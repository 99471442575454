@import '../../../../../styles/variables.scss';

.mode-popup,
.timePicker {
  justify-content: center;
  .numberinputComponent-container,
  .dropdown {
    margin: 0.5rem 0 0.5rem 0.5rem;
    height: 62px;
    flex-shrink: 0;

    button {
      margin-top: 0 !important;
      padding: 0 !important;
      min-width: unset;
    }
  }

  .btn-group.open .dropdown-toggle {
    box-shadow: none !important;
    background: transparent !important;
  }

  .dropdown {
    width: 198px;

    &.open .caret {
      &:before {
        transform: rotate(225deg);
        top: 24px;
      }
    }

    .dropdown-menu {
      top: 101%;

      li:first-child {
        border-top: none;
      }
    }

    & button:hover {
      background-color: transparent !important;
    }
  }

  .dropdown-toggle {
    height: 62px;
    border-radius: 6px;
    background: transparent;
    border: none;
    box-shadow: none;

    .textWrapper {
      display: block;
      width: 136px;
      height: 62px;
      line-height: 62px;
      text-align: center;
      border-radius: 6px;
      background: #fff;
    }
    .caret {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 62px;
      height: 62px;
      background-color: #818181;
      border-top: 0;
      border-radius: 6px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 12px;
        left: 16px;
        width: 24px;
        height: 24px;
        border: solid #414042;
        border-width: 0px 6px 6px 0;
        transform: rotate(45deg);
      }
    }
  }
  .displaytime {
    color: $font-color-tiles;
    line-height: 3.5rem;
    font-size: 3rem;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}

.timePicker .textWrapper {
  border: 1px solid #414042 !important;
}
