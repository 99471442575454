@import '../../../../../styles/media-queries.scss';
.selected-device {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background: linear-gradient(#f7f7f7, #f4f4f4);
  margin-bottom: 1.5rem;
  padding: 2rem;

  .device__wrapper {
    padding: 0px 30px 10px;
  }
}
