.chart-config-wrapper {
  &__topContent {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 6px;
    padding: 1rem 1.2rem;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    i {
      font-size: 1.2rem;
      color: #414042;
    }
  }
  &__expand-button {
    float: right;
  }
  &__topContent--left {
    align-self: center;
  }
  &__checkbox-info {
    font-size: 12px;
  }
}

.chart-config {
  border-radius: 6px;
  background-color: #dfdfdf;
  font-weight: 400;
  &__device {
    transition: 0.3s;
    margin-bottom: 1rem;
  }
  &__button-container {
    margin-top: 20%;
    button {
      margin: 1rem auto;
    }
    .button--cancel {
      border-radius: 50%;
      padding: 8px;
      min-width: 35px;
      height: 35px;
      cursor: pointer;
      background: #dfdfdf;
      box-shadow: 0 0 15px #0000005c;
      position: absolute;
      top: -27px;
      right: -13px;
      i {
        color: black;
      }
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 15%;
    font-weight: bold;
  }
  &__message {
    font-size: 1rem;
    padding: 0rem 1rem;
    margin: 6px 0px;
  }
  &__premium-history-message {
    font-size: 12px;
    color: grey;
    padding: 0rem 1rem;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    display: inline-block;
    width: 25%;
    padding: 0 0.5rem;
  }
  .checkboxComponent label:before {
    margin-right: 12px;
  }
  .checkboxComponent input:checked + label:after {
    top: auto;
  }
}

.chart-parameter-list-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: 2rem 1rem 1rem 3rem;
}

form:focus-within .parameter-title-container span {
  font-size: inherit;
  padding: inherit;
}

.btn-container {
  display: flex;
  gap: 16px;
  margin: 10px 0 20px 50px;
}

@media (min-width: 768px) {
  .chart-config-modal-dialog {
    min-width: 700px;
  }
}
