.registration-form-input {
  min-height: 90px !important;
}

.notification-message {
  .page-link {
    color: white;
    text-decoration: underline;
  }
}
