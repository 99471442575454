@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

.device-menu-bar[disabled] {
  color: gray;
  &,
  span {
    cursor: not-allowed;
  }
}

.devices__wrapper {
  .sk-circle .sk-child:before {
    background-color: black;
  }

  .disconnect-system {
    display: inline-block;
    cursor: pointer;

    .fa-stack {
      line-height: 1.5em;
    }
  }
}

@media screen and (max-width: $tablet) {
}

@media screen and (max-width: $mobile) {
}
