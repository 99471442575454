@import '../../../../../styles/media-queries.scss';
@import '../../../../../styles/mixins.scss';

$tabs-bg-color: #a81524;
$borderColor: #bbb;
$toggle-switch-color: #2ea345;

.notification {
  &__description-text {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  &__isSystemAvailable {
    font-size: 1.7rem;
    font-weight: bold;
    margin-top: 8rem;
    color: red;
  }

  .tabBlock-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .titleComponent {
    margin: 2em 0 1em;
    color: #414042;
    font-weight: 700;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}

.tabBlock-tab {
  width: 50%;
}

.systemList {
  width: 100%;

  &--disable {
    display: none;
  }

  &-item {
    padding: 1rem 2rem;
    min-height: unset;
    display: flex;
    border: 0.5px solid $borderColor;

    &--header {
      background: linear-gradient(#fff, #ededed);
      font-weight: 700;
    }

    &--value {
      width: 77%;
      text-align: left;

      &--last {
        width: 20%;
      }
    }

    &--systemNameAndNumber {
      width: 77%;
      display: flex;

      &__name {
        text-align: left;
        margin-top: 1rem;
        font-weight: bold;
      }

      &__serialNumber {
        margin-top: 1rem;
      }
    }

    &--toggleText {
      width: 75.6%;
      text-align: left;
      margin-top: 1.5rem;
    }

    .checkbox-wrapper {
      width: 40px;
      height: 40px;
      padding-top: 0;
      margin-top: 4px;
      border: 1px solid #414042;
      border-radius: 4px;
    }

    .checkboxComponent label {
      margin: 0;

      &:before {
        border: none;
        width: auto;
        height: 34px;
        border-radius: 6px;
        padding: 15px;
        background-color: white;
      }
    }

    .checkboxComponent input:checked + label:after {
      left: 13px;
      top: 6px;
      border: solid #414042;
      border-width: 0px 6px 6px 0;
    }

    .switch {
      position: relative;
      display: inline-block;
      margin: 0.75rem 0;
      min-width: 4rem;
      max-width: 4rem;
      height: 2.5rem;

      &[disabled] {
        cursor: not-allowed;

        & .slider {
          cursor: not-allowed;
        }

        &:hover + .tooltip {
          opacity: 1;
          bottom: 80px;
          top: auto;
          left: 30%;
        }
      }

      & input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      & .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 4rem;
      }

      & .slider:before {
        position: absolute;
        content: '';
        height: 2rem;
        width: 2rem;
        left: 0.25rem;
        bottom: 0.25rem;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }

      & input:checked + .slider {
        background-color: $toggle-switch-color;
      }

      & input:checked + .slider:before {
        -webkit-transform: translateX(1.5rem);
        -ms-transform: translateX(1.5rem);
        transform: translateX(1.5rem);
      }

      &--big {
        min-width: 6rem;
        max-width: 6rem;
        height: 3.5rem;
        margin: 1rem 0;

        & .slider {
          border-radius: 6rem;
        }

        & .slider:before {
          height: 3rem;
          width: 3rem;
        }

        & input:checked + .slider:before {
          -webkit-transform: translateX(2.5rem);
          -ms-transform: translateX(2.5rem);
          transform: translateX(2.5rem);
        }
      }

      @media all and (max-width: $desktop) and (min-width: $tablet) {
        margin: 1vw 0;
        min-width: 5.5vw;
        max-width: 5.5vw;
        height: 3vw;

        & .slider {
          border-radius: 8vw;
        }

        & .slider:before {
          height: 2.5vw;
          width: 2.5vw;
          left: 0.25vw;
          bottom: 0.25vw;
          border-radius: 50%;
        }

        & input:checked + .slider:before {
          -webkit-transform: translateX(2.5vw);
          -ms-transform: translateX(2.5vw);
          transform: translateX(2.5vw);
        }

        &--big {
          margin: 0.75vw 0;
          min-width: 8vw;
          max-width: 8vw;
          height: 4.5vw;

          & .slider {
            border-radius: 8vw;
          }

          & .slider:before {
            height: 3.9vw;
            width: 3.9vw;
            left: 0.3vw;
            bottom: 0.3vw;
            border-radius: 50%;
          }

          & input:checked + .slider:before {
            -webkit-transform: translateX(3.4vw);
            -ms-transform: translateX(3.4vw);
            transform: translateX(3.4vw);
          }
        }
      }
    }
  }
}
