@import '../../../../../../../../styles/media-queries.scss';
@import '../../../../../../../../styles/variables.scss';

.updateCardDetail {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  &__wrapper {
    width: 100%;
    margin-bottom: 220px;
    display: flex;
    justify-content: space-between;
  }

  &__cardDetails {
    width: 60%;
    padding: 8px 16px;
    text-align: left;
    border-radius: 10px;
    line-height: 25px;
    background-color: $cardDetailBackgournd;
    p {
      margin: 0;
    }
  }

  &__buttonWrapper {
    align-self: center;
  }

  &__paymentSettings {
    width: 100%;
  }

  &__updateCard-button {
    padding: 0 10px !important;
    height: 59px;
    background-color: $updateCardButtonBackground;
  }
}

@media (max-width: $mobile) {
  .updateCardDetail {
    &__wrapper {
      flex-direction: column;
      text-align: left;
    }

    &__updateCard-button {
      margin-top: 20px;
      min-width: 200px;
    }

    &__cardDetails {
      width: 100%;
    }
  }
}
