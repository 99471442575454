@import '../../../../../styles/variables.scss';
.system-profile-address-section {
  .dropdown-menu {
    margin-top: 9px;
  }

  button[disabled] {
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.address-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .inputComponent {
    margin-right: 20px;
    width: 100%;
  }

  .dropdown {
    margin-right: 20px;
    line-height: 1.4em;

    .inputComponent {
      margin-right: 0;
    }
  }
}
.postalCode-error {
  font-weight: 400;
  margin-top: 30px;
  color: $errorMessage;
}
