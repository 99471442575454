@import '../../../styles/variables.scss';

.link {
  font-weight: 400;

  &--secondary {
    background: linear-gradient($button-orange, $button-orange-dark);
    background: none;
    background-color: $button-orange;
    color: #fff;
    transition: 1s ease;
    padding: $space-md;
    text-align: center;
    min-width: $btn-min-width;
    border-radius: 30px;
    transition: 0.2s ease-in-out;
    font-size: 1.2rem;

    &:active {
      background: linear-gradient(#e0491b, #d65f3b);
    }

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      background: linear-gradient($button-orange-hover, $button-orange-dark-hover);
    }
  }

  &--primary {
    background: linear-gradient($button-blue, $button-blue-dark);
    color: #fff;
    padding: $space-md;
    text-align: center;
    min-width: $btn-min-width;
    border-radius: 30px;

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
    }
  }

  &--basic {
    color: $link-basic-color;
    text-decoration: none;
    &:hover {
      color: $link-basic-color;
      text-decoration: underline;
    }
  }

  &--default {
    background-color: #e2e2e2;
    color: #414042;
    padding: $space-md;
    text-align: center;
    min-width: $btn-min-width;

    &:hover,
    &:focus {
      color: #414042;
    }
  }

  &[disabled] {
    opacity: $disabled-opacity;
    cursor: not-allowed;
  }

  &:active {
    opacity: $active-opacity;
  }
}
