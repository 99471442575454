@import '../../../../../../styles/media-queries.scss';
@import '../../../../../../styles/variables.scss';

.ProductModal {
  .ProductTitle {
    margin-top: -0.5rem;
    font-size: 30px;
    font-weight: 600;
  }

  font-weight: 400;
  font-size: 17px;

  &__heading {
    height: 56px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 29px;
  }

  &__subscription-wrapper {
    height: fit-content;
    width: 50vw;
    border-radius: 10px;
    background-color: #f2f2f2;
    padding: 15px;
    margin-bottom: 20px;
  }

  &__partition {
    box-sizing: border-box;
    height: 3px;
    border: 2px solid #bfbfbf;
    margin-bottom: 12px;
  }

  &__content-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  &__product-heading {
    width: 80%;
    font-size: 18px;
    font-weight: 400;
    line-height: 3rem;
    text-align: left;
  }

  &__taxAndSubtotal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #414042;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 35px;
  }

  &__subtotal-wrapper {
    text-align: left;
  }

  &__tax-wrapper {
    text-align: right;
  }

  &__shippingAndTotal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 129px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 35px;
  }

  &__key-wrapper {
    text-align: left;
  }

  &__value-wrapper {
    text-align: right;
  }

  &__total-price-heading {
    font-weight: bold;
  }

  &__total-price {
    font-weight: bold;
  }

  &__paymentSettings {
    margin-top: 220px;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button-wrapper {
    align-self: center;
    display: grid;
    justify-items: center;
    button {
      padding: 16px 40px;
      min-width: 200px;
      border-radius: 46px;
      width: fit-content;
    }
  }

  &__checkbox-wrapper {
    margin-bottom: 220px;
    height: 90px;
    width: 100%;
    border-radius: 10px;
    background-color: $cardDetailBackgournd;
    margin-top: 50px;
    display: flex;
    padding: 5px 17px 5px 41px;
  }

  &__errorMessage {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    color: red;
  }

  .productModal__payment-button {
    background: linear-gradient(180deg, #ff5722 0%, #e84e1d 100%);
  }

  &__toPayment-button {
    display: flex;
    justify-content: center;
    margin-top: 220px;
    button {
      min-width: 200px;
      background: $confirmButtonBackground;
    }
  }

  &__cancel-button {
    margin-top: 10px;
    background-color: #828282;
  }

  .popup-modal {
    width: fit-content;
    border-radius: 10px;
    background-color: #dfdfdf;
    padding: 44px;
    margin-right: 0px;
  }
}

@media (max-width: $tablet) {
  .ProductModal {
    &__tax-wrapper {
      text-align: right;
    }
    &__subscription-wrapper {
      width: 100%;
    }
    &__value-wrapper {
      width: auto;
    }
    &__paymentSettings {
      width: 100%;
    }
  }
}

@media (max-width: $mobile) {
  .ProductModal {
    &__content-wrapper {
      display: block;
    }
  }
  .responsive-heading {
    display: none;
  }
  .responsive-shippingAndTotalWrapper {
    display: flex !important;
  }
}
