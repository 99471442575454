@import '../../../styles/mixins.scss';

.invitation {
  padding-top: 50px;
  padding-left: 100px;
  width: 80%;
  max-width: 1210px;

  & .sk-circle .sk-child:before {
    background-color: #000;
  }

  &__wrapper {
    margin-top: 2em;
  }

  & .row {
    margin-bottom: 0.5rem;
  }

  & .intro {
    margin: 63px 0;
  }

  .intro-1 {
    .bold-text {
      margin: 0;
    }
  }

  & .bold-text {
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.5em;
    margin: 1rem;
  }

  & button#systemId {
    border: 2px solid #ccc !important;
    padding: 1rem 0 1rem 2rem;
    margin-top: 15px !important;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
    padding-left: 4px;
  }

  .permission-error-message {
    margin-top: 10px;
    color: red;
  }
  .permission-error-message:before {
    content: '* ';
  }

  &-system-list-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .bold-text {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
