@import '../../../../../styles/media-queries.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables.scss';

$tabs-bg-color: #a81524;
$info-color: rgb(153, 153, 153);

.toggle-text {
  font-size: 1.4em;
  color: #414042;
  line-height: 3rem;
}
.expand-button {
  font-size: 3.5rem;
  float: right;
  cursor: pointer;
  & i {
    color: $tabs-bg-color;
  }
}
.item-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.8rem;

  & .item-info-wrapper {
    width: 100%;

    &__topContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &--left {
        display: flex;
      }

      &--text {
        padding: 0.75rem 0;
      }
    }

    &__alarm-timestamp {
      margin: 1rem 0 0;
      font-size: 1.5rem;
      line-height: 2rem;
      .item-date-wrapper {
        display: inline-block;
      }
    }

    &__alarm-message {
      margin-right: 10px;
    }

    &__item-icon-wrapper {
      margin-right: 1rem;
    }
  }
  & .description-separator {
    width: 97%;
    margin: 2.5rem 0;
    border-bottom: 1px solid rgba(192, 192, 192, 0.8);
  }
  & .item-name {
    font-weight: 400;
    border-right: 2px solid gray;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    padding-right: 1.5rem;
    float: left;
    margin-right: 1.5rem;

    &--extended {
      border-right: none;
    }
  }
  & .item-id {
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
  }
  & .item-date {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #585858;
  }
  & .messages-wrapper {
    display: none;
  }
  & .item-message {
    margin-bottom: 1.25rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-overflow: ellipsis;
    overflow: hidden;

    &:empty {
      min-height: 1.25rem;
      margin-bottom: 0;
    }
  }

  & .item-date {
    display: inline-block;
  }

  & .item-icon {
    float: left;
    font-size: 3.5rem;

    .fa-info-circle {
      color: #454545;
    }
  }

  & .discard-button-wrapper {
    display: none;
  }
  & .close-button-wrapper {
    float: right;
  }
  & .close-button {
    border: none;
    font-weight: bold;
    font-size: 1.2em;
    background: transparent;
  }

  & .resetButton {
    position: relative;
    margin-bottom: 20px;
  }

  .itemButton {
    border-radius: 5rem;
    background-color: #5d5d5d;
    color: white;
    font-size: 1.2rem;
    padding: 16px 46px;
    margin-bottom: 10px;
    border-width: 2px;
    border-style: outset;
    border-color: #d5d5d5;
    border-image: initial;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &[disabled] {
      &:hover + .tooltip {
        left: 0% !important;
      }
    }

    &--delete {
      background-color: $tabs-bg-color;
    }
  }
  & .discard-button {
    border-radius: 5rem;
    background-color: $tabs-bg-color;
    color: white;
    font-size: 1.2rem;
    padding: 16px 46px;
    margin-bottom: 20px;

    &:disabled {
      background-color: #999999;
    }
  }
  &__extended {
    & .expand-button {
      position: absolute;
      bottom: 2.8rem;
      right: 2.8rem;
    }

    & .messages-wrapper {
      display: flex;
      flex-direction: column;
      margin: 1rem 5rem 2rem 5rem;
    }
    & .discard-button-wrapper {
      position: relative;
      margin-top: 2.5rem;
      display: flex;
      justify-content: space-between;

      .sk-circle {
        position: absolute;
        margin: 0;
        transform: translate(-50%, -50%);
        top: 50%;
        right: 50%;
      }
    }
  }
}
.item-wrapper:not(.item-wrapper__extended) .messages-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
.item-wrapper:nth-child(2n-1) {
  & :not(.item-wrapper__extended) .messages-wrapper::after {
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(238, 238, 238, 1));
  }
}

@media screen and (max-width: $tablet) {
  .item-wrapper {
    .item-date {
      margin: 0 1.5rem;
    }

    .item-icon {
      font-size: 4rem;
    }

    & .item-name {
      border-right: none;
    }

    .item-info-wrapper {
      &__topContent {
        &--text {
          padding: 0;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .item-wrapper .item-name {
    float: none;
    border-right: none;
  }
  .description-wrapper {
    width: 100%;
  }
  .description-icon {
    float: left;
    margin: 1em 0 0 2em;
  }
}
.phone-icon,
.email-icon,
.name-wrapper {
  display: inline-block;
  vertical-align: top;
}
.partner-label,
.name-wrapper .name {
  display: inline-block;
  line-height: 2.5rem;
  padding-right: 10px;
  font-size: 1.2em;
}
.partner-label {
  font-weight: bold;
}
.phone-icon,
.email-icon {
  font-size: 2.2em;
  margin-top: 5px;
}
.phone-icon {
  color: $button--secondary;
  margin-right: 10px;
}
.partner-info-wrapper.extended {
  margin-bottom: 4rem;
}
.info {
  position: absolute;
  width: max-content;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5em;
  padding: 0.5em 0.8em;
  display: flex;
  justify-content: space-between;
  border: 1px solid $info-color;
  margin-top: 5px;
  border-radius: 3px;
  background: white;
  transition: visibility 500ms, opacity 500ms;
  & a {
    text-align: center;
    line-height: 2rem;
  }
  & i {
    padding: 0 5px;
    font-size: 1.2em;
    line-height: 2rem;
    color: black;
    margin-left: 7px;
  }
}
.info:before {
  content: '';
  position: absolute;
  left: 7px;
  top: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 7px 5px;
  border-color: transparent;
  border-bottom-color: white;
  z-index: 3;
}
.info:after {
  content: '';
  position: absolute;
  left: 6px;
  top: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 9px 6px;
  border-color: transparent;
  border-bottom-color: #999999;
}

.switch {
  &[disabled] {
    &:hover + .tooltip {
      left: -7% !important;
    }
  }
  &:hover + .tooltip {
    top: auto !important;
    bottom: 80px !important;
    left: -7% !important;
    opacity: 1 !important;
  }
}
