@import '../../../../../../../styles/media-queries.scss';

.selected-device {
  .update-available {
    .update-info {
      padding: 20px;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
    }
    .device-name {
      font-weight: normal;
      font-size: 17px;
    }
    .release-link {
      padding-left: 0px;
      padding-bottom: 8px;
      text-decoration: underline;
      color: #7d7c7c;
    }
    .version-info {
      margin: 15px 0px 10px;
      color: #7d7c7c;
    }
    .small-text {
      font-size: 14px;
      line-height: 2;
    }
    .button-download {
      margin-top: 30px;
    }
    .button-download[disabled]:hover + .tooltip {
      opacity: 1;
      bottom: 65px;
      top: auto;
      left: 41%;
    }
    .device-name {
      font-weight: normal;
      font-size: 17px;
    }
    .button {
      padding: 10px 70px;
      font-weight: 300;
      margin: 20px;
    }
    .button--secondary[disabled]:hover + .tooltip {
      opacity: 1;
      bottom: 180px;
      top: auto;
      left: 51%;
    }
    .close-button {
      float: right;
      font-size: 1.5rem;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }

    .button-wrapper {
      justify-content: center;
    }

    .action-buttons {
      display: flex;
      justify-content: center;
    }
  }
}

.confirm-message {
  .popup-modal {
    padding: 25px;
  }

  .text {
    font-size: 18px;
    line-height: 28px;
    margin: 8px 53px 28px 53px;
  }
}

@media screen and (min-width: $smallDesktop) {
  .update-info,
  .action-buttons {
    text-align: center;
  }
}

@media screen and (max-width: $tablet) {
}
