@import '../../../../../styles/variables.scss';

.account-migration-info-modal {
  &-wrapper {
    position: fixed;
    top: 84px;
    right: 25px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    width: 533px;
    min-height: 181px;
    background-color: $profileMigrationInfoModalBackground;
    color: #fff;
    box-shadow: 0px 0px 18px 1px rgba(47, 47, 66, 0.15);
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &-description {
    font-size: 16px;
    line-height: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 430px;
  }

  &-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    border: none;
    background-color: transparent;
  }
}
