@import './media-queries.scss';

@mixin contentLayoutWrapper() {
  max-width: 1500px;
  margin: 0 auto;
}

@mixin h1() {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 800;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin h2() {
}

@mixin h3() {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

@mixin h4() {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
}

@mixin label() {
  font-weight: 300;
  font-size: 18px;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  @media all and (max-width: $tablet) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
