.pagination {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  margin: 4em 0 !important;
  width: 100%;

  .page {
    background-color: #c8c8c8;
    color: #000;

    margin-right: 1em;
    border-radius: 2.5em;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .selected-page {
    background-color: #1a227e;
    color: #fff;
    cursor: default;
  }
}
