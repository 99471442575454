@import '../../../../../../../styles/media-queries.scss';

.PaymentDetails {
  .PaymentDetailsTitle {
    height: 30px;
    font-size: 30px;
    font-weight: 700;
    line-height: 28px;
    margin-top: -0.5rem;
  }

  &__paymentDetails-wrapper {
    width: auto;
    border-radius: 10px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    padding: 9px 25px 0 25px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 1em;
  }

  &__paymentDetails-col1 {
    display: flex;
  }

  &__statusAndDate {
    width: 80%;
    font-size: 18px;
    letter-spacing: 0.01px;
    line-height: 35px;
    display: flex;
  }

  &__paymentStatus {
    text-align: left;
  }

  &__statusUpdatedAt {
    text-align: left;
    margin-left: 25px;
  }

  &__subscriptionType {
    margin-top: 9px;
    letter-spacing: 0.01px;
    line-height: 20px;
  }

  &__totalPrice {
    font-weight: 700;
    letter-spacing: 0.01px;
    line-height: 35px;
  }

  &__paymentStatus {
    text-transform: capitalize;
  }

  &__subscription-wrapper-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__paymentIdAndCardNo {
    width: auto;
    border-radius: 10px;
    background-color: #f2f2f2;
    display: flex;
    text-align: center;
    padding: 9px 25px 0 25px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 0.5em;
    flex-flow: row nowrap;
  }

  &__paymentDetails-col1 {
    width: 80%;
  }

  &__paymentDetails-col2 {
    display: flex;
    width: 100%;
  }

  &__paymentId {
    font-size: 17px;
    text-align: left;
    word-break: break-all;
  }

  &__cardNo {
    font-size: 17px;
  }
}

@media (max-width: $mobile) {
  .PaymentDetails {
    &__statusAndDate {
      justify-content: space-between;
    }
    &__paymentDetails-wrapper {
      flex-direction: column;
    }
  }
}
