@import '../../../styles/variables.scss';

.numberinputComponent {
  &-container {
    display: flex;
    height: 100%;
    width: 100%;
    margin-bottom: 1.5rem;

    .text-replacer {
      font-size: 1.5rem;
      line-height: 1.7rem;
      text-align: left;
      margin: 14px 10px;
      min-width: 234px;
      max-width: 234px;
      height: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  &__button {
    text-align: center;
    display: flex;
    justify-content: center;
    min-width: 80px;
    user-select: none;

    &[disabled] {
      cursor: not-allowed !important;

      a {
        cursor: not-allowed !important;
      }

      &:hover + .tooltip {
        opacity: 1;
        top: -25px;
        left: calc((100% - #{$tooltip-width}) / 2);
      }
    }
  }

  &__button:hover {
    cursor: pointer;
  }

  &__plus {
    background-color: #4bb945;
    color: #ffffff;
    transition: all 0.4s ease;
  }

  &__minus {
    background-color: #828282;
    transition: all 0.4s ease;

    &:hover {
      background: #666566;
      background-color: #666566;
    }
  }

  &__input:disabled {
    background: #ffffff;
    color: #414042;
    font-size: 1.5rem;
    line-height: 3.5rem;
    max-width: 254px;
    text-align: center;
    border-style: none;
  }

  &__element {
    align-self: center;
    max-width: 100%;

    a {
      color: #fff !important;
    }
  }

  &__buttonicon {
    text-decoration: none;
    color: white;
    font-size: 1.5em;
    user-select: none;
  }

  &__buttonicon:hover {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
}
