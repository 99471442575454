@import '../../../../../styles/media-queries.scss';
@import '../../../../../styles/mixins.scss';

$tabs-bg-color: #a81524;
$title-color: #ff5a00;

.service-partner-wrapper {
  float: left;
  line-height: 2.5em;
  width: 100%;
  & .title {
    color: $title-color;
    font-size: 2.1em;
    margin: 10px 0 15px 0;
    padding-bottom: 10px;
  }
  & .first-column {
    padding-left: 0;
  }
  & .copy-link {
    display: inline;
    margin-left: 7px;
  }
  & .label {
    color: #414042;
    font-size: 1.3em;
    padding-left: 0;
    padding-bottom: 0;
  }
  & .value {
    line-height: 1.3em;
    font-size: 1.3em;
  }
  & .name,
  .phone,
  .email {
    margin-bottom: 13px;
  }
  & .address {
    .value {
      display: block;
      line-height: 1.4em;
    }
  }
  & .copy-link.copied {
    color: rgba(27, 93, 168, 0.5);
  }
  & .service-partner-not-found {
    font-size: 1.3em;
  }
}
