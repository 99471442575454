.modal-container {
  background-color: #ddd;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.modal-heading {
  font-size: 20px;
  align-self: center;
}

.modal-text {
  color: #414042;
  font-weight: 400;
}

.modal-help-text-heading {
  margin-top: 60px;
  font-size: 14px;
}

.modal-help-text {
  font-size: 16px;
  margin-top: 20px;
}

.back-button {
  margin: 100px 100px 40px 100px;
  width: 200px;
  align-self: center;
}
