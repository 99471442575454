.vacation-day {
  z-index: 1;
  width: 62px;

  &-disabled {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: repeating-linear-gradient(297deg, transparent 1px, transparent 10px, #fff 11px, #fff 12px);
    height: 26px;
    width: 62px;
  }
}

.rdrDayPassive {
  .vacation-day-disabled {
    background: none;
  }
}
