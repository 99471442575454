.selected-device {
  .device-name {
    font-weight: normal;
    font-size: 17px;
  }
  .update-restarting {
    .status-section {
      margin-top: 50px;
      text-align: center;
    }
    .sk-circle {
      margin-top: 25px;
    }
  }
}
