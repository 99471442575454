.weekly-weather-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5rem;
  margin-bottom: 10px;

  &__data {
    display: flex;
    align-items: center;
  }

  .heading {
    font-weight: bold;
  }

  .day-of-week {
    font-size: 1.1rem;
    font-weight: bold;
    width: 25%;
    text-align: left;
  }

  .weekly-section-icon {
    margin-right: 4rem;

    img {
      width: 50px;
      height: 50px;
      text-align: center;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
    }
  }

  .weekly-section-temperature {
    margin-left: 1rem;
    font-size: 1.1rem;
    text-align: right;
    min-width: 4rem;
  }
}
