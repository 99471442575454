@import '../../../../../styles/variables.scss';

.paymentSettings {
  .paymentSettingsTitle {
    margin-top: 0;
    font-size: 30px;
    font-weight: 600;
  }

  &__errorMessage {
    font-size: 17px;
    font-weight: 500;
    color: $errorMessage;
    margin-top: 50px;
  }
}
