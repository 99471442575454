@import '../../../styles/mixins.scss';
@import '../../../styles/media-queries.scss';

//header

.header-container {
  box-shadow: 2px 6px 12px 0 rgba(50, 50, 50, 0.14);
  z-index: 3;
}

.header-container-content {
  @include contentLayoutWrapper();
  padding: 0 15px;
  display: flex;
  .header-container-content {
    flex-direction: row;
  }

  nav {
    align-self: center;
    margin: 0 0.8em 0 0;
  }

  background-color: #fff;
  justify-content: space-between;
  & nav {
    margin-right: 0;
  }

  .mobileMenu {
    margin: 1rem 0;
    display: none;
  }
}

div#language-selector {
  height: auto;
  margin-bottom: 3px;
  padding-right: 0;
  border: none;

  i {
    cursor: pointer;
  }
}

// menu & menu items
.header-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  > li {
    cursor: pointer;
  }

  &-controls {
    user-select: none;
    margin: 0 10px;
  }
}

header ul li {
  list-style-type: none;
  font-size: 1.1em;
  & a {
    text-decoration: none;
    color: #414042;
    align-items: center;
  }
  & a:active {
    color: #000000;
    font-weight: 300;
  }
}

// icons
header ul li .fa {
  font-size: 2.4em;
  color: black;
}

.profileMenuIcon {
  background-image: url('http://placehold.it/36x36');
  background-size: contain;
}

@media screen and (max-width: $mobile) {
  .header-container-content {
    & nav {
      margin-left: 0;
    }
    & ul li {
      padding: 0;
      margin: 0 4vw;
    }
    & .header-menu {
      padding-left: 0;
      justify-content: center;
    }
  }
}

@media screen and (min-width: $desktop) {
  .header-container-content {
    padding: 0;
  }
}

@media screen and (max-width: $tablet) {
  .header-container-content {
    .mobileMenu {
      display: block;
    }
  }
}
