@import '../../../styles/mixins.scss';
@import '../../../styles/media-queries.scss';
$title-color: #414042;

.system-profile-page {
  margin: 2.4em;
  width: 100%;
  .titleComponent {
    color: $title-color;
    font-size: 2.1em;
    margin: 0rem 0rem 2rem;
  }
  .profile-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button {
      text-align: left;
    }
  }
  .tabordion {
    .tab-content {
      padding: 0 0 0 60px;
      min-width: 80px;
      width: calc(100% - 250px);
    }
  }
}

.common-info-section {
  h1 {
    margin-top: 0px;
  }
}

.system-profile-form {
  .form-value-label {
    font-weight: bold;
  }

  .no-padding {
    padding-top: 0px;
  }
}

.text-danger .fa-unlink {
  color: #a83131;
}

.about-manufacturer {
  .hero__logo-rectangle {
    margin: 2rem 0;
    float: left;
  }

  .city,
  .region,
  .postal-code {
    display: inline-block;
  }

  .region,
  .city {
    padding-right: 0.15rem;
  }

  .region,
  .postal-code {
    padding-left: 0.15rem;
  }

  .website {
    font-weight: 500;
    text-decoration: underline;
  }
}

.btn {
  line-height: unset !important;
  font-size: inherit !important;
  border: none !important;
}

.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus {
  border-color: none;
}

@media only screen and (max-width: $tablet) {
  .system-profile-page {
    padding: 0rem;
    margin: 0;
    .tabordion {
      flex-direction: column;
      .tab-content {
        padding: 2rem 0rem;
        width: 100%;
        .second-column {
          padding-left: 0px;
        }
      }
      .profile-section {
        margin-top: 2rem;
        margin-left: -15px;
        margin-right: -15px;
        display: inline-flex;
        flex-direction: row;
        height: fit-content;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scroll-behavior: smooth;
        .option-label {
          margin-right: 5px;
          width: auto;
          white-space: nowrap;
          padding: 2px 20px;
          scroll-margin: 5px;
          &:first-child {
            margin-left: 2rem;
          }
          &:last-child {
            margin-right: 2rem;
          }
        }
      }
      .profile-section::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
