@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/media-queries.scss';
@import '../../../../../styles/variables.scss';

.desktopMenu {
  display: block;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: inherit;
  width: 100%;

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
  }

  &__item {
    position: relative;
    cursor: pointer;
    width: auto;
    padding: 0 1.5rem;
    @include label();
    font-size: 1.25rem;
    display: inline-block;
    margin-left: 0;

    &--languageSelector {
      cursor: auto;
    }

    &--right {
      margin-left: auto;
    }

    &--right ~ &--right {
      margin-left: 0;
      height: 4.6rem;
      display: flex;
      align-items: center;
    }

    .caret {
      margin-left: 0.5rem;
    }

    &:hover,
    &:focus,
    &:focus-within {
      .desktopSubMenu {
        display: block;
      }
    }
  }

  button.desktopSubMenu__button {
    padding: 0 2rem;
    color: #414042;
    border-radius: unset;
    font-weight: 300;
    background: transparent;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }

  &__itemLink {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    height: 4.6rem;

    &:hover {
      text-decoration: underline;
      color: var(--menu-link-hovered);
    }

    &--icon {
      display: inline-block;
      height: 2.5rem;
      width: 2.5rem;
      vertical-align: middle;
      background-repeat: no-repeat;
      color: #454545;
    }
    & > span:first-child {
      line-height: 1;
    }
  }

  &__itemLinkIcon {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    background: yellow;
  }

  .badge {
    margin-right: 5px;
  }

  .badge.badge-danger {
    background-color: #f25220;
  }
}

.desktopSubMenu {
  display: none;
  position: absolute;
  left: 0;
  top: 4.6rem;
  min-width: 100%;
  z-index: 25;
  padding-bottom: 1rem;
  width: 200%;

  &__list {
    float: left;
    display: flex;
    flex-direction: column;
    background: white;
    padding-bottom: 1rem;
    box-shadow: 0px 10px 10px 0 rgba(50, 50, 50, 0.15);
  }

  &__link:hover {
    color: #414042;
  }

  &__link,
  &__link-disabled {
    white-space: nowrap;
    padding: 0 2rem;
    @include label();
    line-height: 2rem;
  }

  &__link-disabled {
    cursor: default;
    color: $desktopMenuFontColor;

    &:focus {
      color: $desktopMenuFontColor;
      text-decoration: none;
    }

    &:hover {
      color: $desktopMenuFontColor;
      text-decoration: none;
    }
  }

  &--expand-left {
    right: 0;
    left: auto;
    top: 4.6rem;
  }

  &__storeLink {
    padding: 0 2rem;
    @include label();
    line-height: 2rem;
    text-decoration: none !important;
    color: #414042 !important;
    width: 220%;
    height: auto;
  }

  &__iotStoreLink,
  &__iotStoreLink:active {
    text-align: inherit;
    height: auto;
    color: inherit;
    background: transparent;
    border: none;
    padding: 0;
  }
  &__iotStoreLink:hover {
    text-decoration: underline;
  }

  .badge {
    margin-left: -20px;
    margin-top: -2px;
    margin-right: 5px;
    padding: 1px 5px;
  }
}

@media screen and (max-width: $tablet) {
  .desktopMenu {
    display: none;
  }
  .desktopSubMenu__storeLink {
    padding: 0;
  }
}
