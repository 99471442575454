@import '../../../../../styles/media-queries.scss';
@import '../../../../../styles/variables.scss';

.Product {
  .ProductTitle {
    margin-top: -0.5rem;
    font-size: 30px;
    font-weight: 600;
  }

  &__description {
    color: $font-color;
    font-family: Roboto;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 0.01px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  &__cartError,
  &__unsupportedCountry_description {
    font-weight: 400;
    margin-top: 30px;
    color: $errorMessage;
  }

  &__description-CTC_services {
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 18px;
  }

  &__subscription-wrapper {
    height: auto;
    border-radius: 13px;
    background-color: $cardDetailBackgournd;
    border: 5px solid $font-color-footer;
    padding: 10px;
  }

  &__subscription-content-wrapper {
    padding: 20px 20px 5px 25px;
  }

  &__subscription-heading {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 1em;
    line-height: 1.5em;
  }

  &__validTo {
    font-weight: 400;
    font-size: 17px;
  }

  &__existing-subscription-paragraph {
    font-weight: 400;
    font-size: 17px;
    margin: 40px 0px 30px 0px;
    line-height: 20px;
  }

  .product__subscription-button {
    padding: 0px 10px !important;
    height: 53px;
    width: 185px;
    border-radius: 27px;
    background: linear-gradient(180deg, $resumeSubscriptionLinearGradient1 100%, $resumeSubscriptionLinearGradient2 0%);
  }

  &__price {
    font-size: 17px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__error {
    margin-top: 40px;
    font-weight: 400;
    color: $errorMessage;
  }

  &__description-free_services {
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 17px;
  }
}

@media (max-width: $tablet) {
  .Product {
    &__subscription-wrapper {
      width: inherit;
    }
  }
}
