@import '../../../styles/mixins.scss';
@import '../../../styles/media-queries.scss';
@import '../../../styles/utils.scss';

.hero {
  color: white;
  position: relative;
  height: 30em;
  background-size: cover;
  overflow: visible;

  &__tint {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  &__small-heading {
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: pxToRem(18);
    font-weight: 800;
    text-transform: uppercase;
    display: inline;
    position: relative;
    z-index: 1001;

    &:before {
      content: '';
      width: 100px;
      height: 3px;
      background: #fff;
      position: absolute;
      left: -120px;
      top: 10px;
    }

    &:after {
      content: '';
      width: 100px;
      height: 3px;
      background: #fff;
      position: absolute;
      right: -120px;
      top: 10px;
    }
  }

  &__logo-wrapper {
    z-index: 10;
    @include contentLayoutWrapper();
    width: 100%;
    margin: 0 auto;
  }

  &__systemSelector {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;

    .btn-group.open .dropdown-toggle,
    .btn-default:focus,
    .btn-default.focus {
      background: transparent !important;
    }
    .dropdown {
      font-size: 3rem;

      &.open {
        background: transparent !important;
      }
    }
  }

  &__systemSelector-wrapper {
    text-align: center;
    @include contentLayoutWrapper();
  }

  &__logo {
    margin-top: 0;
    float: right;
    max-width: 12.5em;
    max-height: 12.5em;
  }

  &__logo-rectangle {
    margin-top: -1px;
    float: right;
    max-width: 12.5em;
    max-height: 5em;
  }

  &__contentWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 40em;
  }

  &__largeHeading {
  }

  &__smallHeading {
    position: relative;
    display: inline-block;
    text-align: center;

    &::before {
      content: '';
      width: 100px;
      height: 2px;
      background: #fff;
      position: absolute;
      left: -120px;
      top: 10px;
    }

    &::after {
      content: '';
      width: 100px;
      height: 2px;
      background: #fff;
      position: absolute;
      right: -120px;
      top: 10px;
    }
  }

  &__button {
    color: white;
  }

  @media screen and (max-width: $tablet) {
    height: 20em;

    &__logo {
      max-width: 8em;
      max-height: 8em;
    }

    &__logo-rectangle {
      max-width: 8em;
      max-height: 3.2em;
    }
  }
  @media screen and (max-width: $mobile) {
    height: 15em;

    &__logo {
      max-width: 6em;
      max-height: 6em;
    }

    &__logo-rectangle {
      max-width: 6em;
      max-height: 2.4em;
    }

    &__small-heading {
      font-size: 1em;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }
  }
}
