.popover {
  padding: 0 !important;
  border: none !important;

  &.schedule-tooltip {
    max-width: none !important;
  }

  &-content {
    background: #5d5d5d;
    color: #fff;
    border-radius: 6px;
    text-align: center;
  }

  & > .arrow {
    right: -10px !important;

    &:after {
      border-left-color: #5d5d5d !important;
    }
  }

  &-title {
    background-color: transparent !important;
    color: #fff;
    border: none !important;
  }

  .settings {
    &-list {
      padding: 0;
    }

    &-item {
      list-style-type: none;
      display: flex;
      margin: 6px 0;

      &--name,
      &--value {
        background-color: #fff;
        color: #5d5d5d;
        border-radius: 6px;
        padding: 8px 12px;
      }

      &--name {
        flex-grow: 1;
        text-align: left;
      }

      &--value {
        width: 90px;
        margin-left: 6px;
      }
    }
  }
}
