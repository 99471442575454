/* Variables */

$bg-welcome: #c4c4c4;
$bg-footer: #25505e;
$bg-timeStamp: rgba(255, 255, 255, 0.6);
$font-color-footer: #fff;
$button--secondary: #00963f;
$button-orange: #ff5722;
$button-orange-dark: #e84e1d;
$button-orange-hover: #ff4409;
$button-orange-dark-hover: #d94516;

$button-blue: #1a237e;
$button-blue-dark: #0b1259;
$button-blue-hover: #151c6b;
$button-blue-dark-hover: #0b1154;
$button-red: #a83131;
$button-red-hover: #8d2929;

$button-default: #828282;
$button-default-hover: #6d6d6d;
$font-color: #414042;
$font-color-tiles: #454545;
$font-color-highlighted: #555;
$font-color-welcome: #656565;
$font-color-footer-link: #fff;
$font-color-timeStamp: #000;
$toggle-switch-color: #2ea345;
$myuplink-text-color: #414042;
$link-basic-color: #2151c5;

$space-md: 16px 40px;
$space-lg: 24px 60px;
$space-sm: 12px 24px;
$font-size-md: 1.2rem;
$font-size-lg: 1.6rem;
$font-size-sm: 0.8rem;
$font-size-xl: 32px;
$disabled-opacity: 0.5;
$active-opacity: 0.7;
$btn-min-width: 160px;
$tooltip-width: 200px;

$menu-link-hovered: #23527c;

$desktopMenuFontColor: #d8d8d8;

$welcomePageBackground: #dfdfdf;
$worksWithBackground: #ffffff;
$workWithBrandBorder: #b7aeae;
$inputPlaceholderColor: #626060;
$borderColor: #bfbfbf;
$errorMessage: red;
$cardDetailBackgournd: #f2f2f2;
$updateCardButtonBackground: #828282;
$resumeSubscriptionLinearGradient1: #e84e1c;
$resumeSubscriptionLinearGradient2: #fe5520;
$confirmButtonBackground: linear-gradient(180deg, #ff5722 0%, #e84e1d 100%);
$policyLinkColor: #1a237e;

$accordionBackground: #f6f6f6;
$accordionIconColor: #1d1929;
$invitationTableBackground: #ffffff;

$profileMigrationInfoModalBackground: #eeab46;
