.addCardDetail {
  width: 100%;

  &__addCard-button {
    height: 59px;
    width: 264px;
    margin-top: 200px;
    border-radius: 46px;
  }

  &__noCardMessage {
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
    text-align: left;
  }

  &__descriptionWrapper {
    text-align: left;
  }
}
