@import '../../../styles/media-queries.scss';
@import '../tile.scss';
@import '../../../styles/variables.scss';

.generic-tile {
  $tile-selector: '.generic-tile';
  @include tile($tile-selector);

  &__tile-heading {
    color: #414042;
    max-height: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__tile-help-icon {
    font-size: x-large;
    cursor: pointer;
    &:hover .tooltip {
      opacity: 1;
      bottom: 80px;
      top: 0;
      left: 40%;
      &::after {
        top: 23px;
      }
    }
  }
  .display-parameter {
    line-height: 3.5rem !important;
    font-size: 3rem !important;

    .top-index {
      top: -1.4rem !important;
      font-size: 1rem !important;
    }
  }
  .no-prices-cont {
    display: block;
    text-align: center;
    padding: 1rem 0 5rem;
    .no-prices-title {
      font-size: $font-size-lg;
      font-weight: 700;
    }
    .no-prices-subtitle {
      font-size: $font-size-md;
      font-weight: 400;
    }
  }
}
