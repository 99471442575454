@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

.tile {
  opacity: 1;
  width: calc(50% - 1rem);
  min-height: 15.625rem;
  margin: 0.5rem;
  position: relative;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  &--dragging {
    .tile__contentWrapper {
      background-color: pink;
    }
  }

  &__contentWrapper {
    position: relative;
    top: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    z-index: 1;
    transition: width 0.25s, margin 0.25s, z-index 0s 0.5s;
    overflow: hidden;
    min-height: 100%;
    max-height: 10000px;
    border-radius: 0.5rem;
    transition: left 0.25s, top 0.25s, opacity 0.25s;

    &--right {
      margin-left: 0;
      transition: left 0.25s 0.12s, top 0.25s 0.12s, opacity 0.25s 0.12s;
    }

    &--not-loaded {
      opacity: 0;
      left: 1.5rem;
      top: 0.5rem;
    }

    &--expanded {
      width: calc(200% + 1rem);
      .tile__edit_button {
        right: 1rem;
      }

      .tile__delete_button {
        right: 4rem;
      }

      &.tile__contentWrapper--right {
        margin-left: calc(-100% - 1rem);
      }

      transition: width 0.25s, margin 0.25s;
    }

    &--is-bright {
      .icon {
        filter: invert(0.8);
      }
    }
  }

  &--wide {
    width: 100%;

    .tile__contentWrapper--expanded {
      width: 100%;
      z-index: 10;
    }
  }

  &__expand_button,
  &__minimize_button {
    opacity: 0.5;
    width: 1.5rem;
    height: 1.5rem;

    display: block;
    position: absolute;
    z-index: 15;
    color: white;
    cursor: pointer;

    bottom: 0.75rem;
    right: 0.75rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border: none;
    padding: 0;
    background-color: transparent;

    .icon {
      width: 80%;
      height: auto;
      display: block;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: white;
    }
  }

  &__drag_handle {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 1.4rem;
    right: 0.2rem;
    z-index: 15;

    .icon {
      cursor: pointer;
      width: 120%;
      margin: -20%;
    }
  }

  &__menu_button {
    opacity: 0.5;
    width: 1.5rem;
    height: 1.5rem;

    display: block;
    position: absolute;
    z-index: 15;
    color: white;
    cursor: pointer;

    top: 0.75rem;
    right: 0.75rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border: none;
    padding: 0;
    background-color: transparent;

    .icon {
      width: 80%;
      height: auto;
      display: block;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: white;
    }
  }

  &__edit_button {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 1rem;
    right: 3rem;
    z-index: 15;

    .icon {
      cursor: pointer;
      width: 80%;
      margin: 10%;
    }
  }

  &__delete_button {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 1rem;
    right: 5.5rem;
    z-index: 15;

    .icon {
      cursor: pointer;
      width: 75%;
      margin: 10%;
    }
  }

  &__demo-heading {
    position: absolute;
    padding: 0;
    margin: 0;
    top: calc(50% - 18px);
    left: 0;
    right: 0;
    text-align: center;
  }

  @media all and (max-width: $desktop) {
  }
  @media all and (max-width: $smallDesktop) {
  }
  @media all and (max-width: $tablet) {
    padding-bottom: 0px;
    width: 100%;
    min-height: 20rem;

    &__contentWrapper {
      position: static;
      &--right {
        left: 0;
        right: auto;
      }

      &--expanded {
        width: 100%;
        height: 100%;

        &.tile__contentWrapper--right {
          margin-left: 0;
        }
      }
    }

    &__drag_handle {
      position: absolute;
      height: 2.5rem;
      width: 2.5rem;
      top: 1.7rem;
      right: 1rem;
      z-index: 15;
    }

    &__edit_button {
      height: 2.5rem;
      width: 2.5rem;
      top: 1.3rem;
      right: 4rem;
    }

    &__delete_button {
      height: 2.5rem;
      width: 2.5rem;
      top: 1.3rem;
      right: 7rem;
    }
  }

  @media all and (max-width: $mobile) {
    min-height: 15rem;
  }
}

.tileMenu {
  display: none;

  &--open {
    display: inherit;

    .tileMenu__overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      opacity: 0.25;
    }
  }

  &__overlay {
    display: none;
  }

  &__wrapper {
    background: #fff;
    position: absolute;
    top: 2.5rem;
    right: 1rem;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    box-shadow: #888 0 0 10px 0;
    z-index: 5;
  }

  &__itemLink {
    @include label();
    line-height: 1.75rem;
  }

  @media all and (max-width: $tablet) {
    &__itemLink {
      line-height: 3.5rem;
    }
  }
}
