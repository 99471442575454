@import '../../../styles/mixins.scss';
@import '../../../styles/media-queries.scss';

.store-page {
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: 5em;
}

.store-page .tab {
  height: 75px;
  width: 229px;
  font-size: 17px;
  padding: 10px 20px;
  background: #e6e6e6;
  color: #414042;
  font-weight: 400;
  border: 2px solid #fff;
  line-height: inherit;
  cursor: pointer;
  span:first-child {
    height: 2.75rem;
    display: inline-flex;
    align-items: center;
  }
}

.store-page .tab.selected {
  height: 75px;
  width: 229px;
  color: #fff;
  background-color: #8d8d8d;
}

.store-page .tab-content {
  min-width: 80px;
  padding: 12px 0 0 60px;
  width: calc(100% - 230px);
  height: 100%;
}

.remove-icon {
  margin-left: 30px;
  font-size: 16px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  padding: 7px 12px;
  border-radius: 19px;
}

.remove-icon.icon-danger {
  background-color: #f25220;
}
.shopping-cart-tab {
  display: inherit;
}
.shopping-cart-tab :first-child {
  width: 50%;
}

@media (max-width: $tablet) {
  .store-page {
    flex-direction: column;
  }
  .store-page .tab-content {
    padding: 32px 0 0 0;
    width: auto;
  }
  .store-section {
    display: flex;
    word-break: break-word;
  }
  .tab.selected {
    width: 33% !important;
  }
  .tab {
    width: 33% !important;
  }
  .shopping-cart-tab :first-child {
    display: flex;
    width: auto;
    word-break: keep-all;
  }
}

@media (max-width: $mobile) {
  .shopping-cart-tab :first-child {
    display: flex;
    width: auto;
    word-break: keep-all;
  }
  .remove-icon.icon-danger {
    margin-left: 0px;
    padding: 5px 10px;
    font-size: 8px;
    height: fit-content;
    margin-left: 2px;
  }
}
