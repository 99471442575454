.demo-verifier-wrapper {
  position: relative;
  width: auto;
  display: inline-block;

  & .tooltip {
    left: unset;
    top: unset;
    bottom: unset;
    right: unset;
  }

  &:hover .tooltip {
    transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    opacity: 1;
  }

  &.top .tooltip {
    left: -20px;
    bottom: 130%;
  }

  &.left .tooltip {
    right: 130%;
    top: -5px;

    &::after {
      top: 40% !important;
      left: 100% !important;
      rotate: -90deg !important;
      margin-left: 0;
    }
  }

  &.right .tooltip {
    left: 100%;
    top: 5px;

    &::after {
      top: 40%;
      left: 0;
      rotate: 90deg;
      margin-left: -10px;
    }
  }

  &.bottom .tooltip {
    left: -20px;
    top: 130%;

    &::after {
      top: 0;
      left: 50%;
      rotate: 180deg;
      margin-top: -10px;
    }
  }
}

.demo-verifier-wrapper-content {
  & button,
  & input,
  & select,
  & textarea,
  & div,
  & span,
  & svg,
  & a {
    pointer-events: unset !important;
    cursor: not-allowed !important;
  }

  & div,
  & span,
  & svg,
  & a {
    opacity: 0.7;
    text-decoration: none !important;
  }
}
