@import '../../../../../../../styles/media-queries.scss';
@import '../../../../../../../styles/variables.scss';

.resumeSubscription {
  font-size: 1.063rem;
  font-weight: 400;

  .subscriptionTitle {
    margin-top: 0;
    font-size: 1.875rem;
    font-weight: 600;
  }

  &__description {
    margin-bottom: 30px;
  }

  &__button-wrapper {
    display: flex;
  }

  &__resumeSubscription-button {
    height: 59px;
    width: 350px;
    border-radius: 46px;
    background: linear-gradient(180deg, $resumeSubscriptionLinearGradient1 100%, $resumeSubscriptionLinearGradient2 0%);
  }

  &__back-button {
    height: 59px;
    width: 320px;
    border-radius: 46px;
    background-color: $button-default;
    margin-left: 20px;
  }

  .popup-modal {
    min-height: 300px;
    border-radius: 10px;
    background-color: $welcomePageBackground;
    padding: 44px;
    margin-right: 0px;
    text-align: left;
    align-items: normal;
    justify-content: center;
  }
}

@media (max-width: $tablet) {
  .resumeSubscription {
    &__button-wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__back-button {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}
