.rdrDateDisplayWrapper {
  display: none !important;
}

.time-selector {
  display: flex;
  margin-top: 2em;
  align-items: center;

  & div {
    margin: 0 1em;
    flex: 2;
  }

  .dropdown-toggle .textWrapper {
    width: 134px;
  }

  .time-selector-label {
    flex: 1;
    text-align: left;
    line-height: 2em;
  }
}

.vacation-details {
  display: flex;
  flex-direction: column;

  .inputComponent {
    width: 335px;
    align-self: center;

    .inputComponent__field {
      padding: 0.8rem 1rem;
    }
  }
  .vacation-enabled {
    background: white;
    width: 332px;
    margin: 16px 0 0;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 1rem;

    span {
      font-weight: 500;
      font-size: 1rem;
    }

    .toggle-switch-wrapper {
      transform: scale(0.8);
      height: auto;
      width: auto;
      margin: 0;

      .switch .slide {
        background-color: #ddd;
      }
    }
  }
}

.mode-popup {
  .vacation-schedule-step {
    width: 100%;
  }

  .popup-main {
    margin-bottom: auto;

    .rdrNextPrevButton {
      margin: 0 1rem;
    }

    .rdrCalendarWrapper {
      margin-top: 16px;
    }
  }

  .titleComponent {
    margin: 0;
    font-size: 24px;
  }

  .button--cancel {
    border-radius: 50%;
    padding: 8px;
    min-width: 35px;
    height: 35px;
    cursor: pointer;
    background: #dfdfdf;
    box-shadow: 0 0 15px #0000005c;
    position: absolute;
    top: -18px;
    right: -18px;
    i {
      color: black;
    }
  }

  .text {
    margin: 16px 0 0;
  }
}
