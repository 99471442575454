@import '../../../../../../styles/media-queries.scss';

.list-item {
  button {
    &.disabled {
      cursor: not-allowed;
      color: #ccc;
    }
  }
}

.system-profile-security-section {
  .list {
    .list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 2px solid #ddd;
      color: #414042;
      padding: 24px;

      button {
        border: none;
        background: transparent;
        cursor: default;
        padding: 0;
      }

      &.disabled {
        cursor: not-allowed;
        color: #ccc;
      }

      &.sort-header {
        span {
          cursor: pointer;
        }
      }

      .name-email-head {
        display: flex;
        width: 70%;
        padding-right: 32px;
      }

      .security-head {
        display: flex;
        width: 30%;
      }

      .security-action-wrap {
        display: flex;
        width: 30%;
        align-items: center;
        justify-content: right;
      }
      .name-email-wrap {
        display: flex;
        width: 70%;
        padding-right: 32px;
      }

      .column {
        font-size: 1.15rem;
        display: flex;
        &.header > span {
          cursor: pointer;
          user-select: none;
        }

        &.user-name {
          width: 30%;
          padding-right: 16px;
        }

        &.user-email {
          width: 70%;
          overflow: hidden;
          align-items: center;
          & a {
            color: #414042;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .copy-link {
            position: relative;
            margin-left: 8px;
            cursor: pointer;
          }
        }

        &.user-securityLevel {
          flex-grow: 1;
          .dropdown {
            font-size: 1rem;
            .textWrapper {
              padding-right: 12px;
            }
            button .caret {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto 0;
              right: 8px;
            }
            button {
              background-color: #c4c4c4 !important;
              color: #414042;

              &:disabled {
                cursor: auto;

                &:hover {
                  color: inherit;
                }
              }
            }
          }
        }

        &.user-actions {
          text-align: center;
          font-size: 1rem;

          .remove-user {
            cursor: pointer;
            margin-left: 5px;
          }
        }
      }

      &:first-of-type {
        font-weight: 700;
      }
    }
  }
}

.security-level-dropdown {
  button {
    padding: 16px;
    min-width: unset;
    margin-top: 0 !important;
  }
}

.button-default {
  padding: 10px 20px;
}

.button-wrapper {
  margin-bottom: 0 !important;
}

.for-error-message {
  margin-top: 30px;
}

@media screen and (max-width: $mobile) {
  .system-profile-security-section {
    .list {
      .list-item {
        .name-email-wrap {
          width: 65%;
          flex-direction: column;
          .column.user-name {
            width: 100%;
          }
          .column.user-email {
            width: 100%;
            margin-left: 0;
          }
        }
        .security-action-wrap,
        .security-head {
          width: 35%;
        }
        .name-email-head {
          width: 65%;
          .user-name,
          .user-email {
            width: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .system-profile-security-section {
    .list {
      .list-item {
        padding: 16px;
      }
    }
  }
}
