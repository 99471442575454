@import '../../../styles/media-queries.scss';

.languageSelector {
  display: inline-block;
  align-items: center;
  color: #454545;
  position: relative;

  &__globeIcon {
    display: inline-block;
  }

  &__selectControl {
    font-size: 18px;
    padding: 0.8rem 0;
    cursor: pointer;
    border: none;
    margin-top: 0.5rem;
    background: transparent;
    text-align: left;
  }

  svg {
    position: absolute;
    right: 1.5rem;
    pointer-events: none;
    top: 24px;
  }
}

@media all and (max-width: $tablet) {
  .languageSelector {
    &__selectControl {
      font-size: 1.8rem;
    }

    svg {
      top: 1.6rem;
      right: 2rem;
    }
  }
}

@media all and (max-width: $mobile) {
  .languageSelector {
    position: relative;

    svg {
      top: 1.4rem;
      right: 2.5rem;
    }
  }
}
