.mode-popup,
.device-scheduling__item,
.dateRangeWrapper {
  .icons {
    display: flex;
    flex-direction: row;
  }
  .icon {
    margin: 12px;
    text-align: center;
    position: relative;
    width: 40px;
    height: 40px;
    float: right;

    .premium-badge-tooltip {
      top: -64px;
    }
    &.disabled:hover .tooltip {
      opacity: 1;
      left: -82px;
      bottom: 46px;
      top: unset;
    }
  }

  .button--round {
    width: 1.5em;
    height: 1.5em;
    font-size: 1.2rem;
    font-weight: 700;
    // outline: none;
  }

  .icon-text {
    display: block;
    padding-top: 36px;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: clip;

    &-brand {
      color: #2aad45;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .icon--default {
    background: none !important;
    color: #454545 !important;
    border: none;
  }
}

.dateRangeWrapper .icon {
  margin-top: 0;
}

.device-scheduling__item .icon {
  margin-left: 30px;
}
