@import '../../../../../../../styles/variables.scss';

.trialSubscription {
  margin-bottom: 16px;
  &__description {
    font-style: italic;
    margin-top: 20px;
    font-weight: 600;
  }

  &__title {
    margin: 20px 0px 10px 0px;
    font-weight: 400;
  }

  &__border {
    box-sizing: border-box;
    height: 3px;
    border: 2px solid $borderColor;
    background-color: $borderColor;
  }

  &__dueDateAndPrice {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 9px 0px 2px 0px;
    font-weight: 400;
  }

  &__price {
    text-align: right;
  }
}
