@import '~react-vis/dist/style';
@import '~react-vis/dist/styles/legends';
@import '../../../styles/variables.scss';
@import '../../../styles/media-queries.scss';

.chart {
  &_wrapper {
    min-height: 15rem;
    padding: 0 3rem;
    width: calc(100% - 6rem);
    background-color: white;

    & svg text {
      fill: black;
    }
  }

  @media all and (max-width: $tablet) {
    &_wrapper {
      margin: 0.5rem 0;
      min-height: 20rem;
      padding-bottom: 2rem;
      width: calc(100% - 1rem);
    }
  }
}

.date-range-item[disabled] {
  cursor: not-allowed;

  color: #454545 !important;
  background-color: #dfdfdf;
  border-left: 1px solid gray;

  &:hover .tooltip {
    opacity: 1;
    left: 40px;
    top: -86px;
  }
}

.date-range {
  cursor: pointer;
  &-selected {
    background-color: green;
    color: white;
  }
}
