@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

// systeminfo
.system-info-wrapper {
  background-color: rgb(155, 152, 152);
  text-align: left;
  margin: 0.5rem;
  border-radius: 0.5rem;
  width: calc(100% - 1rem);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  box-shadow: #888 0 0 10px 0;
}
.status-wrapper {
  padding-left: 1.1rem;
  line-height: 52px;
  & .status {
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;

    &-info {
      display: flex;
      align-items: center;
      height: 54px;

      &:focus-within,
      &:focus {
        .switch {
          outline: auto;
          outline-color: transparent;
        }
      }

      .switch:hover + .tooltip {
        bottom: 54px !important;
        right: -100% !important;
        left: auto !important;
      }
    }
  }
  & .status-text {
    display: inline-block;
    color: white;
    font-size: 1.2em;
    font-weight: 400;
    margin-left: 0.4em;
    vertical-align: middle;
    max-width: 80%;
  }
  & .switch-text {
    color: white;
    font-size: 1.2em;
    font-weight: 400;
    margin-left: 0.4em;
    line-height: initial;

    .button[disabled]:hover + .tooltip {
      left: auto;
    }
  }
  & .toggle-switch-text {
    color: white;
    font-size: 1.2em;
    font-weight: 400;
    margin-left: 0.4em;
    vertical-align: middle;
  }

  & .switch[disabled]:hover + .tooltip {
    display: none;
  }
}

.button.button--sm {
  font-size: 0.9rem;
}

@media screen and (max-width: $tablet) {
  .status-wrapper .switch {
    margin: 0 0 0 0.2em;
  }
  .status-wrapper .status-text {
    max-width: 60%;
  }
}

@media screen and (max-width: $mobile) {
  .status-wrapper .status-text {
    max-width: 45%;
  }
  .status-wrapper .status-text,
  .status-wrapper .switch-text {
    font-size: 1em;
  }
}
