.filter-nibe {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.filter-climatemaster {
  filter: invert(33%) sepia(32%) saturate(607%) hue-rotate(110deg) brightness(93%) contrast(94%);
}

.filter-enertech {
  filter: invert(11%) sepia(69%) saturate(5167%) hue-rotate(189deg) brightness(90%) contrast(104%);
}
