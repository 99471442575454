@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

form:focus-within {
  label span {
    font-size: 0.625em;
    padding: 0 0 0 2em;
  }
}

form {
  height: 100%;

  .form__link {
    border: none;
    background: none;
    padding-right: 0;
    line-height: 2;
    padding: 0.4rem 0rem;
    color: $button-blue;
    font-size: 0.875rem;
    font-weight: 700;
    .inputComponent__placeholder {
      color: $inputPlaceholderColor;
    }

    &:hover {
      color: $button-blue;
    }
  }

  .forgot-link-wrapper {
    text-align: right;
  }

  .inputComponent {
    .inputComponent__field {
      border-radius: 6px;
    }
  }

  .form__paragraph {
    color: #414042;
    font-weight: 400;
    font-size: 1rem;
  }
}

.login-form {
  .button-wrapper--large {
    margin: 0;
    padding: 0;
  }
  .inputComponent {
    .inputComponent__placeholder {
      &__label {
        margin: 0;
      }
      &__field {
        border-radius: 6px;
      }
    }
  }
}

.login-form-azure {
  height: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &-acknowledge {
    font-size: 0.75rem;
  }

  .link-text {
    &:hover {
      color: $button-blue;
      text-decoration: underline;
    }
  }
}

.registerForm {
  &__buttonWrapper {
    margin-top: 4rem;
    padding-bottom: 0;
  }
}

.loginForm,
.acceptedAgreementsForm,
.forgotPasswordForm {
  &__buttonWrapper {
    margin-top: 1rem;
    padding-bottom: 0;
  }
}

.button-wrapper--large {
  margin-top: 48px;
  padding-bottom: 48px;

  a {
    &:hover,
    &:focus {
      text-decoration: none;
      color: #fff;
    }
  }

  .button {
    width: 100%;
  }

  > span {
    display: block;
    text-align: center;
  }

  i {
    margin-right: 40px;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .button--default {
    min-width: 140px;
  }

  .button-wrapper {
    margin-top: 48px;
    padding-bottom: 48px;

    .button {
      margin: 0 16px;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

.orgSelector {
  margin-top: 1rem;
}

.promo-text {
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  margin: 0 4rem;
  text-align: left;
  padding: 1rem;
  font-size: 1rem;
}

.form__heading {
  text-align: left;
  padding-top: 20px;
  margin: 0;
  font-size: $font-size-xl;
  font-weight: 800;
  color: #414042;
  &.confirm-email {
    max-width: 1500px;
    margin: 16px auto;
    padding-top: 40px;
  }
}

.migration-configuration-popup {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px;
  text-align: center;

  h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 17px;
  }
  h4 {
    margin: 20px 0 -20px 0;
  }
  .popup-body {
    font-size: 1.5em;
    font-weight: 300;
  }

  .button-wrapper .button {
    margin-left: 0;

    &.button--default {
      margin-right: 20px;
    }
  }
}

.modal-body {
  padding: 20px 40px !important;
}

.migration-property-selector button {
  font-weight: 300;
}

.migration-configuration-popup > * {
  margin: 4px;
  width: 100%;
}

.sp-selector {
  border: 1px $myuplink-text-color solid;
  padding: 1rem;
}

.confirmation_message {
  margin-top: 15px;
}

.email-not-confirmed-message {
  cursor: pointer;
}

@media screen and (max-width: $tablet) {
  .Login {
    margin-top: 2rem;
  }

  .button-wrapper--large {
    .button {
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width: $mobile) {
  .promo-text {
    margin: 1rem 0 0 2rem;
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
}
