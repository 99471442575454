.accSecurity_wrapper {
  .email-form .inputComponent__placeholder__label {
    margin-top: 0;
  }

  .inputComponent--has-error {
    display: flex;
    flex-direction: column-reverse;
  }

  .inputComponent__field {
    width: 370px;
  }

  max-width: 756px;
}
