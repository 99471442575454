@import '../../../../../styles/media-queries.scss';

.OrderPage {
  color: #414042;
  font-family: Roboto;

  .OrderPageTitle {
    height: 30px;
    font-size: 30px;
    font-weight: 700;
    line-height: 28px;
    margin-top: -0.5rem;
  }

  &__order-number {
    height: 40px;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 20px;
    span {
      font-weight: 500;
    }
  }

  &__buttonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: auto;
  }

  &__back-button {
    height: 53px;
    border-radius: 27px;
    background-color: #828282;
    font-size: 18px;
    line-height: 21px;
    margin-top: 21px;
    text-align: center;
    padding: 8px !important;
  }

  &__pendingMessage {
    font-size: 16px;
    color: red;
    font-weight: 400;
    margin-top: 20px;
  }

  &__print-button {
    height: 53px;
    border-radius: 27px;
    background-color: #828282;
    font-size: 18px;
    line-height: 21px;
    margin-top: 21px;
    text-align: center;
    padding: 1px;
  }

  &__pay-button {
    height: 53px;
    border-radius: 27px;
    background-color: #828282;
    font-size: 18px;
    line-height: 21px;
    margin-top: 21px;
    text-align: center;
    padding: 1px;
  }

  &__cancel-button {
    height: 53px;
    border-radius: 27px;
    background-color: #a83131;
    font-size: 18px;
    line-height: 21px;
    margin-top: 21px;
    text-align: center;
    padding: 1px !important;
  }

  &__payOrCancelWrapper {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  body * {
    visibility: hidden;
  }
  div.OrderPage {
    visibility: visible;
    position: fixed;
    top: 50%;
    left: 60%;
    width: 100%;
    transform: translate(-50%, -50%);
    .ProductDetails__subscription-wrapper {
      height: fit-content;
      width: 861px;
      border-radius: 10px;
      background-color: #f2f2f2 !important;
      padding: 15px;
      margin-bottom: 40px;
    }

    .OrderDetails__detailsWrapper {
      height: fit-content;
      width: 861px;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 40px;
    }

    .PaymentDetails__paymentDetails-wrapper {
      height: 60px;
      width: 861px;
      border-radius: 10px;
      background-color: #f2f2f2 !important;
      display: flex;
      text-align: center;
      padding: 9px 0 0 25px;
      font-size: 18px;
      font-weight: 400;
    }

    & * {
      visibility: visible;
    }

    &__buttonWrapper,
    &__buttonWrapper * {
      visibility: hidden;
    }
  }
}

@media (max-width: $tablet) {
  .OrderPage__payOrCancelWrapper {
    width: 100%;
  }
}
