@import '../../../../../../../styles/variables.scss';

.Subscription {
  &__failedMessage {
    border: 3px solid $font-color-timeStamp;
    margin: 20px 20px 0 20px;
    padding: 10px;

    &__title {
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: $errorMessage;
    }

    &__description {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 0;
      color: $errorMessage;
    }
  }
}
