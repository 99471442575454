.remove-section {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 0;
  margin-bottom: 2rem;
}

.disconnect-system {
  display: inline-block;
  cursor: pointer;

  .fa-stack {
    line-height: 1.5em;
  }

  .disabled {
    color: #ccc;
    cursor: not-allowed;
  }
}

.disconnect-button {
  width: 225px;
}
