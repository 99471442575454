@import '../../../styles/mixins.scss';

.product-registration {
  @include contentLayoutWrapper();

  & h1 {
    margin: 2em 0 1em;
  }
  .button-wrapper {
    display: flex;
  }

  &__form {
    width: 100%;
  }
}

.registered-products-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.registered-products {
  margin-top: 20px;
}

.date-picker-input-field {
  position: relative;
}

#installation-date-icon {
  position: absolute;
  right: 8px;
  top: 12px;
}

.react-datepicker-wrapper {
  width: 1px !important;
  height: 1px !important;
  overflow: hidden;
  position: absolute;
  right: 80px;
  top: 40px;
}

.react-datepicker-popper {
  z-index: 10;
}

.loader-button {
  color: white;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 12px;

  .sk-circle {
    margin: 0;
    width: 25px;
    height: 25px;
  }
  .sk-child:before {
    background: white !important;
  }
}
