@import '../../../../../styles/media-queries.scss';

.header {
  background-color: #fff;
  padding: 0 2rem;
  box-shadow: 0px 28px 27px 0 rgb(65 64 66 / 11%);
  z-index: 0;

  &-inner {
    display: flex;
    .navigation-item a {
      height: auto;
    }
  }
}

@media all and (max-width: $tablet) {
  .header-inner {
    flex-direction: column;
    position: relative;
  }
}
