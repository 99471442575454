@import '../../../styles/mixins.scss';

.update-device-firmware {
  .sk-circle .sk-child:before {
    background-color: black;
  }
  .update-device-firmware--half {
    max-width: 450px;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.firmware-info {
  .disabled {
    pointer-events: none;
    color: #ccc;
  }
  .info-panel {
    background-color: #f4f4f4;
    padding: 1rem;
    padding-top: 0.1rem;
    margin-bottom: 1rem;

    .left {
      float: left;
      clear: left;
      margin-bottom: 1rem;
    }

    .right {
      float: right;
      clear: right;
      margin-bottom: 1rem;
    }

    .disabled-link {
      cursor: not-allowed;
      position: relative;
      &:hover + .tooltip {
        position: absolute;
        opacity: 1;
        bottom: auto;
        top: auto;
        left: auto;
      }
    }

    .strong {
      font-weight: bold;
    }

    .firmware-download-link {
      text-decoration: none;
      color: #f25220;
    }

    &-footer {
      border-top: 1px dashed #414042;
      margin-top: 1rem;
      padding-top: 0.5rem;
    }
  }
}
