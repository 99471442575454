@import '../../../../../../../styles/media-queries.scss';
@import '../../../../../../../styles//mixins.scss';

.OrderDetails {
  &__detailsWrapper {
    display: flex;
    margin-bottom: 18px;
    flex-flow: row;
    justify-content: space-between;
  }

  &__userDetailsWrapper {
    height: 229px;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
  }

  &__userDetailsTitle {
    font-weight: bold;
    font-size: 18px;
  }

  &__userDetails {
    line-height: 24px;
    word-break: break-word;
  }

  &__companyDetailsWrapper {
    height: 179px;
    font-size: 17px;
    margin-left: 201px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0px;
  }

  &__companyDetails {
    font-weight: bold;
    font-size: 18px;
  }
}

@media (max-width: $tablet) {
  .OrderDetails {
    &__companyDetailsWrapper {
      margin-left: 0px;
    }
    &__userDetails {
      line-height: 20px;
    }
    &__detailsWrapper {
      margin-top: 18px;
    }
  }
}
