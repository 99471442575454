.system-profile-profile-section {
  .label {
    display: block;
  }

  .disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
}
