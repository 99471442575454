@import '../../../styles/variables.scss';

.tooltip {
  width: $tooltip-width;

  &-inner {
    height: auto;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
}
