@import '../../../styles/variables.scss';

.invitation {
  &-selection-info {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  &-table {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 600px;
    padding: 0px;
    background-color: #ddd;
    text-align: center;
    border: 1px solid #bfbfbf;
    width: 100%;
    max-height: 943px;
    overflow: auto;

    &-search-wrapper {
      color: #a9a9a9;
      position: relative;

      .fa-magnifying-glass {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 1.25rem;
      }
    }

    .list {
      padding: 0;
      border-bottom: 2px solid #ddd;
      display: flex;
      flex-direction: row;
      width: 100%;
      background: linear-gradient($invitationTableBackground, $invitationTableBackground);
      &:nth-child(odd),
      &:nth-child(even) {
        background-color: transparent;
      }

      &-item {
        padding: 1rem 2rem;
        min-height: unset;

        &--header {
          background: linear-gradient(#fff, #ededed);
          font-weight: 700;

          .inputComponent {
            &__field {
              height: 38px;
              border-radius: 35px;
              width: 100%;
              min-width: auto;
              padding-right: 35px;
              font-size: 0.875rem;
            }

            &__placeholder__label {
              margin: 0;
            }
          }
        }

        &--value {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;

          &:first-child {
            padding-left: 5%;
          }

          .checkbox-wrapper {
            padding: 0;
            margin: 0;
          }
        }

        &-data {
          margin-top: 17px;
        }
      }
    }

    .checkbox-wrapper {
      .checkboxComponent {
        &__label::before {
          padding: 10px;
        }

        input:checked + label::after {
          top: 2px;
          left: 7px;
          width: 10px;
          height: 17px;
          border: solid #414042;
          border-width: 0px 4px 4px 0;
        }

        input:disabled {
          opacity: 0.5;

          & + label::after {
            opacity: 0.5;
          }
        }
      }
    }

    .popup-bottom-panel {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .confirm-button {
      margin-bottom: 10px;
    }

    .button-container {
      width: 40%;
    }

    .circle {
      float: left;
      margin: 13px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #414042;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border: 1px solid #ff5722;
        background-color: #ff5722;
      }
    }
  }

  .row .bold-text {
    margin-left: 0;
  }
}
