.passwordValidationForm {
  &__title {
    height: 45px;
    width: 511px;
    color: #414042;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }

  &__input-password {
    height: 27px;
    width: 300px;
    color: #414042;
    font-family: Roboto;
    font-size: 17px;
    line-height: 20px;
    padding: 15px;
  }

  &__button-wrapper {
    display: flex;
    margin-top: 100px;
    margin-left: 130px;
  }

  &__button-cancel {
    height: 59px;
    width: 127px;
    border-radius: 46px;
    background-color: #828282;
    margin-right: 20px;
  }

  &__button-save {
    height: 59px;
    width: 127px;
    border-radius: 46px;
    background-color: #ff5723;
    margin-left: 20px;
  }

  .popup-modal {
    height: 403px;
    width: 702px;
    border-radius: 10px;
    background-color: #dfdfdf;
    padding: 44px;
    margin-right: 0px;
  }
}
