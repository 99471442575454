@import '../../../../../styles/media-queries.scss';

.Orders {
  .OrderHistoryTitle {
    height: 30px;
    color: #414042;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 700;
    line-height: 28px;
    margin-top: -0.5rem;
  }

  &__orderWrapper {
    height: 272px;
    border-radius: 10px;
    border: 3px solid #fff;
    background-color: #f2f2f2;
  }

  &__contentWrapper {
    height: 180px;
    color: #414042;
    font-family: Roboto;
    font-size: 17px;
    letter-spacing: 0.01px;
    line-height: 30px;
    padding: 23px 0 23px 25px;
    font-weight: 400;
  }

  &__orderPrice {
    margin-top: 5px;
  }

  &__buttonWrapper {
    padding-left: 25px;
  }

  .orders__view-button {
    height: 53px;
    border-radius: 27px;
    padding: 0px 40px;
    background: linear-gradient(180deg, #e84e1c 100%, #fe5520 0%);
  }
  &__isOrderAvailable {
    font-size: 17px;
    font-weight: 400;
  }
}
