.tile-configuration-popup {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px;
  background-color: #dfdfdf;
  text-align: center;

  .popup-input input {
    padding: 0.6em 1em;
    font-size: 1.2em;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 17px;
  }

  .popup-body {
    font-size: 1.5em;
    font-weight: 300;
  }
}

.tile-property-container {
  margin-top: 16px;
}

.tile-property-title {
  display: flex;
  margin-bottom: 6px;
}

.tile-property-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
}

.tile-property-selector {
  width: 465px;

  .dropdown button {
    height: 56px;
    line-height: 1.2em !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .caret {
      right: 1rem;
    }
  }

  .textWrapper {
    padding: 0.75rem 0rem;
    font-size: 0.9em;
  }
}

.tile-property-remove {
  height: 56px;
  width: 56px;
  min-width: 56px;
  margin-left: 8px;
  padding: 6px 12px 6px 12px !important;
  border-radius: 4px !important;
  min-width: 56px !important;
}

.tile-property-selector button {
  font-weight: 300;
}

.tile-configuration-popup > * {
  margin: 4px;
  width: 100%;
}

.popup-bottom-panel {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-left: 12px;
  }
}

.empty-parameter-list {
  margin-top: 50px;
}

.select-tile-type {
  margin-top: 14px;
}

.popup-input {
  display: flex;
  align-items: center;
  justify-content: stretch;

  > * {
    width: 100%;
  }
}
