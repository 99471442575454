.system-profile-info-item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 13px;

  .system-profile-info-item-label {
    color: #414042;
    font-size: 1.3em;
    padding-left: 0;
    padding-bottom: 0;
    margin-right: 0.6em;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
  }

  .system-profile-info-item-value {
    line-height: 1.3em;
    font-size: 1.3em;
    display: inline-block;
  }
}
