@import '../../../styles/mixins.scss';

.smartguide {
  &__wrapper {
    margin: 5em 0;
  }

  h1#smartguide {
    margin-bottom: 1em;
  }

  .smartguide-container {
    min-height: 550px;
    background-color: #fff;
  }

  .smart-guide__tile-heading {
    margin: 30px 0;
    border-radius: 0.25em;
    color: #777;
    font-weight: 400;
    font-size: 20px !important;
    padding: 10px 3px !important;
    text-transform: none;
  }

  button {
    min-width: 30rem !important;
    font-weight: normal !important;
  }

  .smart-guide__tile-description {
    color: #414042;
  }

  .list-group-item.cancel {
    background-color: #cccccc;
  }

  .sk-child:before {
    background-color: #000;
  }
}

.smart-guide {
  .smart-guide__tile-heading,
  .smart-guide__tile-description {
    color: #414042;
  }

  a.numberinputComponent__buttonicon {
    font-size: 2rem;
    min-width: 18%;
  }
}
