.chart-type-wrapper {
  width: fit-content;
  border: 3px solid grey;
  display: flex;
  padding: 0.2rem;
  border-radius: 3px;
  div {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &__horizontal-bar {
    height: 5px;
    width: 1.8rem;
    border-radius: 3px;
    align-self: center;
  }
  &__bar-chart {
    margin: 0 5px;
    div {
      display: flex;
      span {
        align-self: flex-end;
        width: 0.313rem;
        border-radius: 3px;
        background-color: #e2e2e2;
      }
    }
    &__small-bar {
      height: 0.625rem;
    }
    &__large-bar {
      height: 1rem;
      margin: 0 2px;
    }
  }
  &__area-chart {
    position: relative;
    padding-right: 0.5rem;
    div {
      width: 0;
      height: 0;
      border-left: 0.938rem solid transparent;
      border-right: 0.938rem solid transparent;
      border-radius: 3px;
    }
    :nth-of-type(2) {
      position: absolute;
      left: 0.5rem;
      bottom: 0;
    }
  }
}

.chart-list-container {
  display: flex;
  align-items: center;
  width: 32%;
  margin: 7px 7px 0 0;
  padding: 8px;
  border-radius: 5px;
  position: relative;
  p {
    font-weight: 500;
    margin: 0 0.5rem;
    align-self: center;
  }
  .button--delete {
    border-radius: 50%;
    padding: 7px;
    min-width: 25px;
    height: 25px;
    cursor: pointer;
    background: white;
    box-shadow: -2px 4px 10px #0000001b;
    position: absolute;
    top: -9px;
    right: -9px;
    display: none;
    align-items: center;
    justify-content: center;
    i {
      font-size: 15px;
      color: black;
    }
  }
  &:hover {
    background: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.103);
    .button--delete {
      display: flex;
    }
  }
}
