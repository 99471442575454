.AddCard {
  &__descriptionWrapper {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 21px;
    margin-bottom: 30px;
  }

  &__addCard-button {
    padding: 0 10px !important;
    height: 59px;
    width: 185px;
    border-radius: 46px;
    margin-top: 40px;
  }

  &__noCardMessage {
    font-size: 20px;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .checkout-modal-dialog {
    width: fit-content !important;
    margin: auto;
  }
}
