@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.history {
  .chart-config {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5em;
  }
  .premiumMessage {
    margin-top: 50px;
    font-size: 18px;
    font-weight: 500;
    color: $errorMessage;
    &-here {
      a {
        font-size: 18px;
        font-weight: 500;
        color: $errorMessage;
      }
    }
  }
  .titleComponent {
    margin-top: 20px;
  }
  .disabled {
    display: none;
  }

  & .device {
    width: 100%;

    & .accordion-item::before {
      padding: 1rem;
    }

    & h3 {
      padding: 10px 15px;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin: 10px 0;
    }

    & ul {
      padding: 0;

      & li {
        list-style: none;
        display: inline-block;
        width: 33%;
        padding: 0 0.5rem;
      }
    }
  }

  .chart-wrapper {
    width: 100%;
    height: 450px;
  }

  .chart-control-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    margin-top: 12px;

    .checkbox-wrapper {
      padding-top: 0;
      margin-top: 0;
      height: 36px;
      width: 36px;
      margin: 0 auto;
      .checkboxComponent {
        justify-content: center;
      }
    }

    > * {
      margin-left: 1em;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      margin: 0 auto;
    }
    &__button[disabled] {
      i {
        color: grey;
      }
    }
  }

  .crosshair {
    position: absolute;
    z-index: 1;
  }

  .chart-top-controls {
    margin-bottom: 0.5em;
    padding-right: 1em;
  }
  .controls-title {
    font-size: 14px;
    font-weight: 500;
  }

  .date-range {
    background-color: #f6f6f6;
    height: 30px;
    border: 1px solid #5e5e5e;
    flex-wrap: initial;
    > * {
      margin-left: 0;
    }

    .date-button {
      height: 100%;
      font-size: 1.2em;
      min-width: 70px;
      padding: 0 6px;
      color: #5e5e5e;
      border-left: 1px solid #5e5e5e;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      position: relative;

      &:first-of-type {
        border-left: none;
      }

      &.--disabled:hover .tooltip {
        opacity: 1;
        top: -94px;
        left: -60px;
      }
    }

    .--selected {
      color: #fff;
      background-color: #5e5e5e;
    }

    .--disabled {
      cursor: not-allowed;
      color: #5e5e5e;
      background-color: #eee;
      border-left: 1px solid #5e5e5e;
      border-bottom: 1px solid #5e5e5e;
    }
  }

  .rv-discrete-color-legend {
    position: absolute;
    left: 6em;
    top: 1em;
    background-color: rgba($color: white, $alpha: 0.9);
    border: 1px solid #e6e6e9;
    max-height: 200px;
  }

  .mini-chart {
    max-width: 60%;
    margin: 0 auto;
    height: 100px;
  }

  .chart {
    min-height: 750px;
    padding: 1rem;
    margin-bottom: 2rem;
    background: linear-gradient(#f7f7f7, #f4f4f4);
    border-radius: 6px;
  }

  .chart .rv-xy-plot__borders-left.-left,
  .chart .rv-xy-plot__borders-right.-right,
  .full-screen .rv-xy-plot__borders-left.-left,
  .full-screen .rv-xy-plot__borders-right.-right {
    fill: transparent;
  }

  .rv-crosshair__inner__content {
    color: $font-color-timeStamp;
    font-weight: 500;
    background: $bg-timeStamp;
    backdrop-filter: blur(1.5px);
  }

  .full-screen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: white;
    overflow: auto;
    background: linear-gradient(#f7f7f7, #f4f4f4);
    padding: 1rem;
    .chart-wrapper {
      height: 70vh;
    }
    .date-range {
      .--disabled:hover .tooltip {
        top: 40px;
        &::after {
          top: -10px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .spinner-wrapper {
    height: calc(100% - 380px);
    margin: 0 auto;

    .sk-circle {
      top: 22%;

      .sk-child:before {
        background-color: black;
      }
    }
  }
  .export--icon {
    border-radius: 50%;
    padding: 0;
    min-width: auto;
    background-color: #414042;

    img {
      filter: invert(1);
      border-radius: 50%;
      padding: 6px;
    }
  }
  .chart-button {
    margin: 20px 0;
  }
}

.premium-badge-container {
  margin: 0 0.5rem;
}

.premium-badge {
  text-decoration: none !important;
  margin-bottom: 0.5rem;

  &-tooltip {
    display: none;
    pointer-events: none;
    top: -70px;
    left: 5.5em;
  }

  .smart-guide__link &-tooltip {
    left: 24px;
  }

  &:hover + &-tooltip {
    display: block;
    opacity: 1;
  }
}

.svg-inline--fa.fa-w-14 {
  width: 0.5em;
  height: 0.6em;
}

.rv-xy-plot__axis__ticks {
  text-transform: capitalize;
}

.date-range-container {
  display: flex;
  flex-direction: column;
}

.quick-step-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  background: #5e5e5e;
  color: #fff;
  padding: 10px;
  position: relative;
  &__span {
    display: flex;
  }
  p {
    margin: 0;
  }
  & > :first-child {
    min-width: 20%;
    p {
      margin: 0 10px 0 0;
    }
  }
  &__button {
    background: transparent;
    border: none;
  }
  &__button[disabled] {
    cursor: not-allowed;
    color: grey;
  }
}
