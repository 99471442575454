@import '../../../../../../styles/media-queries.scss';

.TermsOfService {
  font-weight: 400;
  font-size: 17px;

  .TermsOfServiceTitle {
    margin-top: 0rem;
    font-size: 30px;
    font-weight: 600;
  }

  &__heading {
    height: 56px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin-bottom: 50px;
  }

  &__description {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: left;
  }

  &__content-wrapper {
    width: 50vw;
    height: 413px;
    border-radius: 10px;
    background-color: #f2f2f2;
    padding: 30px 30px 30px 15px;
    overflow-y: scroll;
    text-align: left;
  }

  &__button-wrapper {
    margin-top: 13.75rem;
    align-self: center;
    display: grid;
    button {
      padding: 16px 40px;
      min-width: 200px;
      border-radius: 46px;
      width: fit-content;
    }
  }

  &__back-button {
    margin-top: 10px;
    background-color: #828282;
  }
  .popup-modal {
    width: fit-content;
    border-radius: 10px;
    background-color: #dfdfdf;
    padding: 44px;
    margin-right: 0px;
  }
}

@media (max-width: $tablet) {
  .TermsOfService__content-wrapper {
    width: inherit;
  }
}
