.formInput {
  &--half {
    width: calc(50% - 40px);
  }

  &--hidden {
    display: none;
  }

  &__field {
    width: 100%;
    padding: 15px 30px;
    font-size: 1.5em;
  }

  &__label {
    margin-bottom: 0px;

    &--left {
      margin-left: 10px;
      float: left;
    }
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }
}
