@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

.smart-guide {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  position: relative;

  &__tile-heading {
    margin: 1.5rem 3rem;
    @include h3();
  }

  &__tile-description {
    margin: 1.5rem 3rem;
    @include label();
  }

  &__info-wrapper {
    width: 50%;
    height: 100%;
  }

  &__link {
    position: relative;
    display: inline-block;
    margin: 0 0 1.5rem 3rem;
  }

  &__image-wrapper {
    position: absolute;
    left: 60%;
    right: 10%;
    top: 0;
    bottom: 0;

    img {
      max-height: 65%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      display: inline-block;
    }

    tile__contentWrapper--expanded {
      width: 100%;
      height: 100%;
    }
  }

  a,
  a:hover,
  a:focus {
    color: #fff;
    text-decoration: none;
  }

  @media all and (max-width: $smallDesktop) {
    &__tile-description {
      font-size: 1.125em;
      line-height: 1.5em;
    }
  }

  @media all and (max-width: $tablet) {
    &__tile-heading {
      @include h3();
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  @media all and (max-width: $mobile) {
    margin: 1.5em 0.5em;

    &__info-wrapper {
      width: calc(100% - 1em);
    }

    &__image-wrapper {
      display: none;
    }

    &__tile-heading {
      margin: 1.5rem 1rem;
    }

    &__tile-description {
      font-size: 1.25em;
      margin: 1.5rem 1rem;
    }

    &__link {
      margin: 1.5rem 1rem;
    }
  }
}
