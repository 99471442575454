.download-link {
  color: #7d7c7c;

  &:hover {
    color: #7d7c7c;
  }
}

.disable-link {
  color: #7d7c7c;
  cursor: not-allowed;
  &:hover + .tooltip {
    opacity: 1;
    bottom: 400px;
    top: auto;
    left: 61%;
  }
}

.disable-download-link {
  color: #7d7c7c;
  pointer-events: none;
  cursor: not-allowed;
}
