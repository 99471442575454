@import '../../../styles/mixins.scss';
@import '../../../styles/media-queries.scss';

.indicator {
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  &__value {
    padding-bottom: 1rem;
    text-align: left;

    &-text {
      position: relative;
      font-size: 4.5rem;
      line-height: 5rem;
      color: #414042;

      .top-index {
        top: -2.4rem;
        @include label();
      }

      .bottom-index {
        bottom: 0;
        left: 0.15em;
        font-weight: 300;
        font-size: 40%;
      }
    }
  }

  &__title {
    top: calc(100% - 1.5rem);
    left: 0;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.23rem;
    text-align: left;
    position: absolute;
    margin-right: 15px;
    color: #585858;
  }

  .value-md {
    font-size: 2rem;
    padding-top: 0.8rem;
  }

  .value-lg {
    font-size: 2.5rem;
    padding-top: 0.6rem;
  }

  .value-xl {
    font-size: 3.5rem;
    padding-top: 0.4rem;
  }
}

@media screen and (max-width: $tablet) {
  .indicator {
    &__value {
      &-text {
        font-size: 6rem;
        line-height: 6.5rem;

        .top-index {
          top: -3.2rem;
          font-size: 1.5rem;
        }
      }
    }

    &__title {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }
  }
}

@media screen and (max-width: $mobile) {
  .indicator {
    &__value {
      &-text {
        font-size: 5rem;

        .top-index {
          top: -2.5rem;
          font-size: 1.5rem;
        }
      }
    }
    &__title {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
  }
}
