@import '../../../styles/media-queries.scss';

.tileManager {
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    #hotWater {
      z-index: 9999;
      .tile__contentWrapper {
        overflow: visible;
        .tile__expanded-section {
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          .timePicker {
            .dropdown-menu {
              max-height: 300px;
            }
          }
        }
      }
    }

    & .addTile {
      background: #dfdfdf;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    & .addTile .button--round span {
      font-weight: 600;
      vertical-align: text-top;
    }

    & .slider-container {
      .input-range__label-container {
        color: #fff;
        left: 0;
      }

      .input-range__label--max {
        right: -1.5rem;
        bottom: -0.1rem;
      }

      .input-range__label--min {
        left: -1.5rem;
        bottom: -0.1rem;
      }
    }
  }

  .rv-xy-plot__grid-lines__line,
  .rv-xy-plot__axis__line,
  .rv-xy-plot__axis__tick__line {
    stroke: #ffffff;
  }

  @media all and (max-width: $mobile) {
    .tileManager {
      &__wrapper {
        &--expand-bottom {
          padding-bottom: 30px;
        }
      }
    }
  }
}
