@import '../../../styles/media-queries.scss';

$mainColor: rgb(46, 163, 69);

$inactiveBackgroudColor: white;
$inactiveBorderColor: lightgrey;

$activeBackgroudColor: $mainColor;
$activeBorderColor: white;

$focusedBorderColor: white;
$mouseDownColor: $mainColor;

$disabledMainColor: grey;
$disabledBackgroudColor: lightgray;
$disabledAndCheckedBackgroudColor: darkblue;

.radio-group-wrapper {
  margin: 0.75rem 0;
}

.radioGroupComponent {
  position: relative;
  display: block;
  min-height: 1.5rem;
  width: 100%;
  font-size: 1.1em;
  line-height: 1.5rem;
  margin: 0 0 1rem 0;

  &__inline {
    display: inline-flex;
    margin-right: 1rem;
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__input:checked ~ &__label:before {
    color: $mainColor;
    border-color: $activeBorderColor;
    background-color: $activeBackgroudColor;
  }

  &__input:focus ~ &__label:before {
    box-shadow: 0 0 0 0.05rem $focusedBorderColor;
  }

  &__input:focus:not(:checked) ~ &__label:before {
    border-color: $focusedBorderColor;
  }

  &__input:not(:disabled):active ~ &__label:before {
    color: $mainColor;
    background-color: $mouseDownColor;
    border-color: $mouseDownColor;
  }

  &__input:disabled ~ &__label {
    color: $disabledMainColor;
  }

  &__input:disabled ~ &__label:before {
    background-color: $disabledBackgroudColor;
  }

  &__label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 2rem;
    font-weight: 400;
  }

  &__label:before {
    pointer-events: none;
    background-color: $inactiveBackgroudColor;
    border: 0.2rem solid $inactiveBorderColor;
  }

  &__label:after,
  &__label:before {
    position: absolute;
    left: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    content: '';
  }

  &__label:after {
    background: no-repeat 50%/50% 50%;
  }

  .radioGroupComponent &__label:before {
    border-radius: 50%;
  }

  .radioGroupComponent &__input:disabled:checked ~ &__label:before {
    background-color: $disabledAndCheckedBackgroudColor;
  }

  .radioGroupComponent__input:disabled {
    &:hover + .tooltip {
      opacity: 1;
      bottom: 80px;
      top: auto;
      left: 30%;
    }
  }

  @media only screen and (max-width: $desktop) {
    margin: 0 0 1vw 0;
  }
}
