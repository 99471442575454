@import '../../../styles/variables.scss';

.setting-parameter-wrapper {
  & .sk-circle .sk-child:before {
    background-color: black;
  }

  .tileSetting {
    &--disabled {
      input,
      span {
        cursor: not-allowed !important;
        pointer-events: none !important;
      }
    }
  }
  select.dropdown {
    background-color: #fff;
    padding: 10px;

    &:hover + .tooltip {
      opacity: 1;
      bottom: 100px;
      left: calc((100% - #{$tooltip-width}) / 2);
      top: auto;
    }
  }

  .tile-badge {
    margin-bottom: 1.5rem;
  }
}

.timePicker {
  display: flex;
}

.param-control {
  display: flex;
  flex-direction: column;
  min-width: 330px;
  min-height: 50px;
}

.settingName {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;

  .sk-circle {
    margin: 0 15px 0;
    height: 25px;
    width: 25px;
  }
}
