.daily-weather {
  &__humidity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    margin: 0 5rem;
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }

  &__section-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 5rem;
  }
}

.daily-weather-section {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  .weather-section-heading {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .weather-section-icon {
    margin: 1.5rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
    width: 60px;
    height: 60px;
  }

  .weather-section-temperature {
    font-size: 1.1rem;
  }
}
