@import '../../../../../../../styles/media-queries.scss';
@import '../../../../../../../styles/variables.scss';

.UpdateCard {
  &__descriptionWrapper {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 21px;
    margin-bottom: 30px;
  }

  &__cardDetails {
    font-weight: 400;
    font-size: 17px;
  }

  &__buttonWrapper {
    display: flex;
    margin-top: 40px;
  }

  &__updateCard-button {
    height: 53px;
    border-radius: 27px;
    background-color: $button-default;
    margin-right: 12px;
  }

  &__removeCard-button {
    height: 53px;
    border-radius: 27px;
    background-color: $button-red;
    margin-left: 11px;
  }
}

@media (max-width: $tablet) {
  .UpdateCard {
    &__buttonWrapper {
      button {
        min-width: 140px;
      }
    }
  }
}
