@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';

@mixin tile($selector) {
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
  border-top-color: inherit;
  min-height: 100%;

  &__tile-heading {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    h4 {
      font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      width: auto;
      display: inline;
      position: relative;
      font-size: 1.25rem;
      color: #414042;
    }
  }

  &__tile-heading {
    margin: 1.5rem 8rem 1.5rem 3rem;

    max-height: 1.25rem;
    line-height: 1.25rem;

    p {
      margin-bottom: 0;
    }
  }

  &__tile-data {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1.5rem 3rem;
  }

  &__parameters {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
  }

  &__display-parameter-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 75%;
    order: 0;

    .indicator {
      width: 50%;
      min-width: fit-content;
      position: relative;

      &::after {
        position: absolute;
        height: 80%;
        content: '';
        z-index: 10;
        left: 100%;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    &.right {
      justify-content: flex-end;
    }
  }

  .setting-parameter-wrapper {
    max-height: 0px;
    width: 0px;
    transition: max-height 0.25s;
  }

  &__icon-wrapper {
    order: 2;
    width: auto;
    max-height: 5.5rem;
    width: 25%;
    max-width: 10rem;
    text-align: center;
    margin-top: 0;

    img {
      max-height: 5.5rem;
    }
  }

  &__graph-wrapper {
    overflow: hidden;
    border-top-color: inherit;
    max-height: 0px;
    transition: max-height 0.25s;
  }

  &__tile-name {
    margin: 0.5em 2em;
    font-size: 1.3em;
  }

  &--expanded {
    padding-bottom: 0;

    #{$selector}__tile-data {
      margin-top: 0;
    }

    #{$selector}__display-parameter-wrapper {
      width: 35%;

      .indicator {
        &:last-child {
          &::after {
            display: block;
          }
        }
      }
    }

    #{$selector}__expanded-section {
      background-color: white;
      color: #414042;
    }

    .setting-parameter-wrapper {
      max-height: fit-content;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: auto;
      padding: 4rem 3rem 1rem;
      justify-content: space-around;

      .tileSetting {
        display: flex;
        justify-content: center;
        position: relative;
        margin: 1.5rem 10%;
        width: 30%;

        .settingControl {
          position: relative;
          text-align: center;

          &--radioGroup {
            text-align: left;
          }

          .toggle-switch-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .switch {
              order: 2;
            }

            .toggle-text {
              text-transform: uppercase;
            }
          }
        }

        .settingName {
          @include label();
          white-space: normal;
          right: 0;
          left: 0;

          &--below {
            top: 100%;
          }

          & p {
            margin-bottom: 0;
          }
        }
      }
    }

    .generic-tile__weather-outdoor {
      .setting-parameter-wrapper {
        flex-direction: column;
        justify-content: space-around;
        gap: 1rem;
        margin-top: 2rem;
        padding: 0;

        .tileSetting {
          position: static;
          margin: 0 5rem;
          width: auto;
        }

        .settingControl {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          position: static;

          .display-parameter {
            font-size: 1.125rem !important;
            line-height: 1rem !important;
          }

          .top-index {
            top: 0 !important;
            font-size: 1.125rem !important;
            margin-left: 5px !important;
          }
        }
      }
    }

    #{$selector}__graph-wrapper {
      max-height: 50rem;
      background: transparent;
      display: flex;
      padding: 1.5em 0 0;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      left: 0px;
      right: 0px;
      top: 0px;

      & .sk-circle .sk-child:before {
        background-color: black;
      }

      .graphName {
        @include label();
        white-space: nowrap;
        left: 0;
        right: 0;
        margin-left: 5rem;
        margin-bottom: 0;
      }
    }

    #{$selector}__graph-placeholder {
      margin-top: 1em;
      width: 100%;
      height: calc(100% - 5.5rem);
      z-index: 1;
    }

    #{$selector}__graph-wrapper-controller {
      margin: 0em 4em 1em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: calc(100% - 8em);
      background: transparent;
      text-align: center;
      height: 35px;
      z-index: 2;
      border-radius: 0;
    }

    .electricalPrice {
      justify-content: center;
      .date-range-item {
        margin: 0;
      }
    }

    #{$selector}__graph-wrapper-controller-item {
      width: 25%;
      margin: auto;
      color: white;
      height: 100%;
      border: 1px solid white;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      position: relative;

      @include label();

      &.date-range-selected {
        color: #454545;
        background-color: white;
      }
    }

    .chart_wrapper,
    .chart_wrapper .rv-xy-plot__inner {
      background: transparent !important;
    }

    .rv-xy-plot__borders-right.-right,
    .rv-xy-plot__borders-left.-left {
      fill: transparent !important;
    }
  }

  @media all and (max-width: $desktop) {
    &__icon-wrapper {
      img {
        max-height: 6rem;
      }
    }
  }

  @media all and (max-width: $smallDesktop) {
    &__tile-data {
      margin: 1.5rem 3rem 0.5rem 3rem;
    }

    &__icon-wrapper {
      max-width: 30%;
    }
  }

  @media all and (max-width: $tablet) {
    #{$selector}__tile-heading {
      margin: 1.5rem 10rem 1.5rem 3rem;
      max-height: 1.5rem;

      h4 {
        font-size: 1.5rem;
      }
    }
    &__tile-data {
      margin: 2rem 3rem 2rem 3rem;
    }

    .setting-parameter-wrapper {
      width: calc(100% - 6rem);
      margin: 0 3rem;
    }

    #{$selector}__icon-wrapper {
      height: 7.5rem;
      max-height: 7.5rem;

      img {
        max-height: 7.5rem;
      }
    }

    #{$selector}__graph-wrapper {
      height: auto;
      position: static;

      .graphName {
        @include label();
      }
    }

    &--expanded {
      .__setting-parameter-wrapper {
        margin-top: 1rem;

        .tileSetting {
          margin: 1rem 1rem 1rem 0;

          .settingName {
            p {
              @include label();
            }
          }
        }
      }
    }
  }

  @media all and (max-width: $mobile) {
    #{$selector}__tile-heading {
      margin: 1.5rem 7rem 1.5rem 1.5rem;
    }

    #{$selector}__tile-name {
      position: static;
      margin: 1em;
    }

    #{$selector}__tile-data {
      margin: 1vw 1.5rem 2rem 1.5rem;
    }

    &--expanded {
      margin: 0.5em;
      width: calc(100% - 1em);

      #{$selector}__tile-heading {
        margin-bottom: 1em;
      }

      .setting-parameter-wrapper {
        margin: 0 1.5rem;
      }

      #{$selector}__expanded-section {
        padding-top: 1rem;
        margin-left: -0.75rem;
        margin-right: -0.75rem;
        margin-bottom: -0.75rem;
      }

      #{$selector}__graph-wrapper {
        display: block;
        width: calc(100%);
        position: static;
        text-align: center;
        order: 5;
      }
    }
  }
}

.tile > div {
  background: linear-gradient(#f7f7f7, #f4f4f4);
  color: #454545;
  border: 1px solid#bfbfbf;

  a {
    color: #414042;
  }
}
