@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

.device-menu-bar[disabled] {
  color: gray;

  &,
  span {
    cursor: not-allowed;
  }
}

.device-settings__wrapper {
  .sk-circle .sk-child:before {
    background-color: black;
  }

  .disconnect-system {
    display: inline-block;
    cursor: pointer;

    .fa-stack {
      line-height: 1.5em;
    }
  }
}

.manage-disabled-message {
  color: darkred;
  margin-top: 12px !important;
}

.devices-list {
  margin-left: 1rem;
}

.devices-list {
  margin-bottom: 2rem;
  width: 70%;
  background: linear-gradient(#f7f7f7, #f4f4f4);

  &-item {
    min-height: 6rem;
    padding: 2rem;

    &-info {
      font-size: 1.5em;
    }

    .accessories-container {
      margin-top: 10px;
    }
  }

  .item-heading {
    font-weight: bold;
    font-size: 22px;
  }

  .item-toolbox {
    font-size: 22px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & div:hover {
      cursor: pointer;
    }
  }

  .item-subheading {
    font-size: 22px;
  }

  .button--bottom-spacing {
    margin-bottom: 1rem;
    padding: 0.6rem 2.8rem;
    min-width: 100px;
  }
}

@media screen and (max-width: $tablet) {
  .list .item-toolbox {
    padding: 0.5rem 1rem;
  }

  .devices-list {
    width: 100%;
  }
}

@media screen and (max-width: $mobile) {
  .list .item-toolbox {
    padding: 1rem;
  }
}
