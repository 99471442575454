@import '../../../../../styles/variables.scss';

$close-button-color: #1d1929;

.notification-container {
  width: unset !important;
  min-width: 320px;
  max-width: 640px;
}

.account-migration-modal {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  &-header {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
  }

  &-content {
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &-close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: $close-button-color;
    font-size: 20px;

    &:hover {
      color: lighten($close-button-color, 30%);
    }
  }

  &-link-wrapper {
    display: flex;
    a {
      text-decoration: underline;
      color: $button-blue;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: bold;
    }

    &.resend {
      margin-left: 6px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      padding: 12px 36px;
      font-size: 16px;
    }

    .button.button--secondary.account-migration-modal-button-error {
      background: none;
      background-image: none;
      background-color: $button-red;

      &:hover {
        background: none;
        background-image: none;
        background-color: darken($button-red, 10%);
      }
    }
    &-otp {
      display: flex;
      flex-direction: column;
      &-text-wrapper {
        display: flex;
      }
    }
  }

  &-form-description {
    margin: 0;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &-content .inputComponent {
    width: 370px;
  }

  &-button-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &-button-spinner {
    width: 20px;
    height: 20px;

    .sk-circle {
      margin: 0;
      width: 20px;
      height: 20px;
    }
  }
}
