@import '../../../styles/mixins.scss';
@import '../../../styles/media-queries.scss';

.home__wrapper {
  padding-top: 2em;

  .premium-badge-tooltip {
    left: unset;
    right: 0;
  }
}

@media all and (max-width: $smallDesktop) {
}
