.tos-content {
  td {
    vertical-align: top;
  }

  td:first-of-type {
    padding-right: 10px;
  }
}

h1#tos {
  margin-bottom: 0;
}
