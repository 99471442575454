@import '../../../../../styles/media-queries.scss';

.system-profile-security-section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .connect-user-section h3 {
    margin: 0;
  }

  .connect-user-section hr {
    border: 1px solid #ddd;
    margin-top: 30px;
    margin-bottom: 0;
  }

  section {
    width: 100%;

    &.user-management-section {
      hr {
        border: 1px solid #ddd;
        width: 98%;
      }

      h3 {
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }

    .dropdown-input {
      button {
        .caret {
          bottom: 24px;
        }
      }
    }
  }

  .dropdown .dropdown-menu li {
    a {
      color: #414042;
    }
    &:first-of-type {
      border-top: none;
    }
  }

  .sk-circle .sk-child:before {
    background-color: black;
  }
}

@media screen and (max-width: $tablet) {
  .system-profile-security-section {
    section {
      .dropdown-input {
        button {
          .caret {
            bottom: 16px;
          }
        }
      }
    }
  }
}
