@import '../../../styles/mixins.scss';

.redeemVoucher {
  section {
    width: 90%;
  }

  .page-description {
    line-height: 1.7rem;
    font-size: 1.2rem;
  }

  .redeem-voucher-form form {
    max-width: 450px;
    width: 100%;
  }

  .notification-message {
    z-index: 99999;
  }

  .error-message-section {
    color: darkred;
  }

  .success-message-section {
    color: #414042;
    line-height: 1.7rem;
    font-size: 1.2rem;
  }
}
