@import '../../../../../../../styles/media-queries.scss';

.ProductDetails {
  &__subscription-wrapper {
    height: fit-content;
    border-radius: 10px;
    background-color: #f2f2f2;
    padding: 15px;
    margin-bottom: 40px;
  }

  &__partition {
    box-sizing: border-box;
    height: 3px;
    width: 100%;
    border: 2px solid #bfbfbf;
    margin-bottom: 12px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__product-heading {
    height: 54px;
    width: 80%;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }

  &__taxAndSubtotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 86px;
    color: #414042;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 35px;
  }

  &__subtotal-wrapper {
    text-align: left;
  }

  &__tax-wrapper {
    text-align: right;
  }

  &__shippingAndTotal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 86px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 35px;
  }

  &__key-wrapper {
    text-align: left;
  }

  &__value-wrapper {
    text-align: right;
    width: 184px;
  }

  &__total-price-heading {
    font-weight: bold;
  }

  &__total-price {
    font-weight: bold;
  }
}

@media (max-width: $tablet) {
  .ProductDetails {
    &__tax-wrapper {
      text-align: right;
    }
    &__subscription-wrapper {
      width: 100%;
    }
    &__value-wrapper {
      width: auto;
    }
  }
}

@media (max-width: $mobile) {
  .ProductDetails {
    &__content-wrapper {
      display: block;
    }
  }
  .responsive-heading {
    display: none;
  }
  .responsive-shippingAndTotalWrapper {
    display: flex !important;
  }
}
