@import '../../../styles/variables.scss';

.option-input-container {
  display: flex;
  justify-content: center;

  .options-list {
    display: flex;
    justify-content: center;
    min-height: 70px;
    width: 100%;
  }

  .options-list[disabled]:hover + .tooltip {
    opacity: 1;
    top: -15px;
    bottom: unset;
    left: calc((100% - #{$tooltip-width}) / 2);
  }

  .button--option {
    background-color: transparent;
    padding: 16px;
  }

  .selected-option {
    img {
      filter: invert(53%) sepia(30%) saturate(4915%) hue-rotate(0deg) brightness(100%) contrast(108%);
    }
    span {
      color: #fe7700;
    }
  }

  .option-items {
    display: flex;
  }

  .option-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #414042;

    .option-icon {
      width: 1.5em;
      height: 1.5em;
    }

    .fallback-text {
      margin-top: 8px;
    }
  }
}
