.textbox {
  width: 100%;
  margin-bottom: 0.8rem;
  &[disabled] {
    cursor: not-allowed;
    &:hover + .tooltip {
      opacity: 1;
      bottom: 80px;
      top: auto;
      left: 30%;
    }
  }

  input[disabled] {
    cursor: not-allowed;
  }

  &__input-field {
    width: 100%;
    min-width: 14rem;
    padding: 0.6rem 0.8rem;
    font-size: 1.5em;
    border: 2px solid #e2e2e2;
    border-radius: 3px;
    color: #414042;
    font-weight: 400;
  }
}

.textbox-content-wrapper {
  text-align: left;

  .fa-question-circle:hover .tooltip {
    opacity: 1;
    bottom: 80px;
    top: auto;
    left: 15%;
  }
}

.inputComponent__error {
  color: #a83131;
  white-space: pre-wrap;
  margin: 7.7px 0 7.7px 0;
  text-align: left;
  font-weight: 400;
}
