@import '../../../styles/mixins.scss';

.contact {
  & .sk-circle .sk-child:before {
    background-color: #000;
  }

  & .field__tip {
    color: #a83131;
  }
  .errorField {
    color: #a83131;
    white-space: pre-wrap;
    margin: 8px 0 8px 0;
    text-align: left;
    font-weight: 400;
  }
}

textarea#message {
  padding: 0.6em 0.8em;
  font-size: 1.3rem;
  font-weight: 400;
  border: 2px solid #ccc;
  border-radius: 3px;
  transition: font-size 200ms, padding 200ms;
}
