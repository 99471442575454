@import '../../../../../styles/media-queries.scss';
@import '../../../../../styles/mixins.scss';

.list {
  .list-item {
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    background: linear-gradient(#f7f7f7, #f4f4f4);
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0rem;
    }
    .detail-item {
      padding: 0px 30px 10px;
      font-size: 16px;
    }
    .accessories {
      .accessory-item {
        padding: 10px 30px;
        border-top: 1px solid #ddd;
      }
    }
    .status {
      &.available {
        color: #f25220;
        background-color: transparent;
        margin-right: -10px;
        cursor: pointer;
      }
      &.updated {
        color: #00963f;
      }
      .icon {
        width: 24px;
      }
    }
  }
  &-item {
    min-height: 6rem;
    padding: 2rem;

    &-info {
      font-size: 1.5em;
    }
  }

  .item-heading {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 5rem;
  }

  .serial-number {
    margin-top: 12px;
    font-size: 16px;
  }

  .item-toolbox {
    font-size: 22px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & div:hover {
      cursor: pointer;
    }
  }

  .item-subheading {
    font-size: 22px;
  }
}
@media screen and (min-width: $smallDesktop) {
  .devices {
    .list {
      .version,
      .update-status {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: $smallDesktop) {
  .devices {
    .list {
      .version,
      .update-status {
        text-align: left;
        margin-top: 10px;
      }
      .item-toolbox {
        padding: 0.5rem 1rem;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .devices {
    .list .item-toolbox {
      padding: 1rem;
    }
    .list {
      .version,
      .update-status {
        text-align: left;
        margin-top: 10px;
      }
    }
  }
}
