.account-migration-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  color: #414042;

  &-divider-wrapper {
    width: 756px;
  }

  &-header {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }

  &-content {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    max-width: 814px;
  }
}
