@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.carousel {
  padding: 64px;
  margin: 0 auto;
  .brand-logo {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .device-logo {
    margin: 5px;
  }

  .brand-logo,
  .device-logo {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .select-value-btn {
    padding: 16px 25px;
  }

  &-inner {
    box-shadow: 0 2px 18px 0 rgba(72, 72, 72, 0.5);
    width: 100%;
    display: flex;
    align-items: center;
    height: 420px;
  }

  &-text {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    margin: 40px auto;
  }

  .item {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .content {
      text-align: center;
      width: 50%;
      padding: 22px;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-weight: 400;
        font-size: 24px;
        color: black;
        margin-top: 2rem;
        line-height: 2.5rem;
        span {
          outline: none;
        }
      }
    }

    &.active,
    &.left,
    &.right {
      display: flex;
    }

    .image {
      width: 50%;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
      }

      .works-with-brands-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 38px;
        .smart-device-logo-wrapper {
          display: flex;
          justify-content: center;
          padding-bottom: 5px;
        }
      }

      .brand-list-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        justify-content: space-between;
        .brand-logo-wrapper {
          margin: 0;
        }
      }
    }

    .googlePlayStore,
    .appStoreButton {
      width: 156px;
      height: 46px;
      display: inline-block;
      cursor: pointer;
      font-size: 0;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .appStoreButton {
      background-image: url('../../../assets/img/app-store-badge.png');
    }

    .googlePlayStore {
      background-image: url('../../../assets/img/google-play-badge.png');
      margin-left: 10px;
    }

    .buttons {
      margin-top: 44px;
    }
  }

  .carousel-control {
    opacity: 1;
    width: 0;
    top: 50%;
    transform: translate(0, -50%);
    &.right,
    &.left {
      background-image: none;
      width: 60px;
      height: 60px;

      &:focus,
      &:active {
        outline: 5px auto #337ab7;
      }
    }
  }

  .carousel-indicators {
    bottom: -3.5rem;

    li {
      background: #828282;
      border-color: #828282;
      width: 8px;
      height: 8px;
      margin: 6px;
      border-radius: 50%;
    }

    .active {
      background: #ff5722;
      border-color: #ff5722;
    }
  }

  .glyphicon {
    background: #1a237e;
    border-radius: 50%;

    &.glyphicon-chevron {
      &-right,
      &-left {
        width: 30px;
        height: 30px;
        font-size: 30px;
      }

      &-right {
        margin-right: 0;
        right: 1rem;
        top: 1.5rem;

        &:before {
          content: '';
          width: 8px;
          height: 16px;
          border-left: solid 10px #fff;
          border-bottom: solid 8px transparent;
          border-top: solid 8px transparent;
          display: inline-block;
          margin-bottom: 6px;
          margin-left: 4px;
        }
      }

      &-left {
        margin-left: 0;
        left: 1rem;
        top: 1.5rem;

        &:before {
          content: '';
          width: 8px;
          height: 16px;
          border-right: solid 10px #fff;
          border-bottom: solid 8px transparent;
          border-top: solid 8px transparent;
          display: inline-block;
          margin-bottom: 6px;
          margin-right: 3px;
        }
      }
    }
  }
}

@media all and (max-width: $largeDesktop_1380) {
  .carousel {
    &-inner {
      height: 376px;
      min-width: 560px;
    }

    .item {
      .content,
      .image {
        height: 375px;
        width: 280px;
      }

      .buttons {
        margin-top: 20px;
      }

      .content h3 {
        font-size: 24px;
      }

      .googlePlayStore {
        margin-left: 0;
      }
    }

    &-text {
      font-size: 18px;
      margin: 20px auto;
    }
  }

  .content.wide.fullPage {
    padding: 0.1rem;
  }

  .content.narrow.fullPage {
    .button {
      padding: 16px;
    }
  }
}

@media all and (max-width: $mediumDesktop) {
  .carousel {
    .item {
      .content {
        padding: 1rem;

        h3 {
          margin: 0;
        }
      }

      .carousel-text {
        margin: 20px auto;
      }

      .buttons-item {
        width: 10rem;

        a {
          margin-left: 0;
          margin-bottom: 6px;
        }
      }
    }
  }

  .wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 100%, #fff 0%) !important;
  }

  .welcome {
    .content.fullPage {
      height: auto;
      width: auto;
    }

    .content.narrow {
      width: auto;
      margin: 60px auto 0;
      padding-bottom: 2rem;
      padding-top: 5rem;

      &.fullPage {
        margin-top: 4rem !important;
      }

      form {
        max-width: 400px;
        margin: 0 auto;
      }
    }

    .carousel {
      margin-top: 40px;

      .glyphicon.glyphicon-chevron-right,
      .glyphicon.glyphicon-chevron-left {
        top: 2rem;
      }

      .glyphicon.glyphicon-chevron-right {
        right: 1.2rem;
      }

      .glyphicon.glyphicon-chevron-left {
        left: 1.2rem;
      }

      &-control {
        top: 42%;

        &.right {
          right: -10px;
        }

        &.left {
          left: -10px;
        }
      }

      &-inner {
        box-shadow: none;
      }
    }
  }
}

@media all and (max-width: $tablet) {
  .carousel {
    .item {
      .buttons-item {
        width: auto;
      }

      .buttons {
        margin-top: 0;
      }

      .googlePlayStore,
      .appStoreButton {
        width: 140px;
        height: 40px;
      }

      .content {
        padding: 2em;
      }
    }
  }

  .wrapper {
    min-height: 450px;
  }
}

@media all and (max-width: $smallTablet) {
  .content.wide.fullPage {
    width: 100%;
    overflow: auto;

    .carousel-control {
      &.right {
        right: -4px;
      }

      &.left {
        left: -4px;
      }
    }
  }
}

@media all and (max-width: $tablet) {
  .carousel {
    .item {
      .buttons-item {
        width: auto;
      }

      .buttons {
        margin-top: 0;
      }

      .googlePlayStore,
      .appStoreButton {
        width: 140px;
        height: 40px;
      }

      .content {
        padding: 2em;
      }
    }
  }

  .wrapper {
    min-height: 450px;
  }
}
@media all and (max-width: $mobile) {
  .carousel {
    .carousel-indicators {
      bottom: 0;
    }
    .carousel-inner {
      min-width: auto;
      height: 600px;
      box-shadow: none;
      .item {
        flex-wrap: wrap;
        background: none;
        padding: 24px;
        .content {
          width: 100%;
          height: auto;
          order: 2;
        }

        .image {
          width: 100%;
          height: auto;
          .brand-logo,
          .device-logo {
            background: $worksWithBackground;
          }
        }
      }
    }
  }
}
