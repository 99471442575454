@import '../../../../../../../styles/variables.scss';

.yearlySubscription {
  font-size: 1.063rem;
  font-weight: 400;
  .subscriptionTitle {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__wrapper {
    height: fit-content;
    line-height: 1.25rem;
    margin-bottom: 10px;
  }

  &__description {
    margin-top: 15px;
  }

  &__subscriptionTitle {
    margin-top: 20px;
  }

  &__border {
    box-sizing: border-box;
    height: 3px;
    border: 2px solid $borderColor;
  }

  &__dueDateAndPrice {
    display: flex;
    justify-content: space-between;
    padding: 9px 0px 2px 0px;
  }

  &__price {
    text-align: right;
  }

  &__resumeSubscription-message {
    margin-top: 30px;
  }

  &__cancellation-message {
    margin-top: 30px;
  }

  &__cancelSubscription-button {
    height: 52px;
    background-color: $button-red;
    margin-top: 25px;
  }

  &__resumeSubscription-button {
    height: 52px;
    background: linear-gradient(180deg, $resumeSubscriptionLinearGradient1 100%, $resumeSubscriptionLinearGradient2 0%);
    margin-top: 25px;
  }
  &__yearlyValidUntilDate {
    padding-left: 10px;
  }
}
