@import '../../../../../../../styles/media-queries.scss';
@import '../../../../../../../styles/mixins.scss';
.selected-device {
  .update-success {
    .device-name {
      font-weight: normal;
      font-size: 17px;
    }
    .progress-message {
      margin-top: 20px;
    }
    .progress-section {
      margin-top: 50px;
      text-align: center;
    }
    .progress {
      height: 8px;
      .progress-bar {
        width: 100%;
        background: linear-gradient(#ff5722, #e84e1d) !important;
      }
    }

    .button {
      padding: 10px 70px;
      font-weight: 300;
    }

    .action-buttons {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (min-width: $tablet) {
  .selected-device {
    .progress {
      margin: 10px 100px;
    }
  }
}

@media screen and (max-width: $tablet) and (min-width: $mobile) {
  .selected-device {
    .progress {
      margin: 10px 0px;
    }
  }
}
