.pp-link {
  border: 1px gray solid;
}

.loader-button {
  color: white;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 12px;

  .sk-circle {
    margin: 0;
    width: 25px;
    height: 25px;
  }
  .sk-child:before {
    background: white !important;
  }
}
