@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.faq .panel-title {
  font-weight: 400;

  & a {
    display: block;
    padding: 10px 15px;
  }
}

.panel-heading {
  padding: 0;
}

#faq-accordion {
  overflow: hidden;
  border-radius: 8px;
  font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  .panel {
    background: $accordionBackground;
    border: unset;
  }
  .panel-heading {
    padding: 0;
    background-color: $accordionBackground;
  }

  .panel-body {
    border: unset;
    font-size: 1rem;
    font-weight: 400;
    padding: 0px 24px 24px;
  }

  .panel-title {
    position: relative;
    a {
      text-decoration: none;
      width: 100%;
      display: inline-block;
      cursor: pointer;
      padding: 24px;
      color: $font-color;
      font-size: 1.25rem;
      line-height: 120%;
      font-weight: 700;
      .expand-icon-cont {
        color: $accordionIconColor;
        position: absolute;
        top: 50%;
        right: 24px;
        width: 16px;
        height: 16px;
        transform: translateY(-50%) rotate(0deg);
        transition: transform 0.2s ease-in-out;
        svg {
          position: absolute;
          transition: opacity 0.2s ease-in;
          &:nth-child(1) {
            opacity: 0;
          }

          &:nth-child(2) {
            opacity: 1;
          }
        }

        &.expanded {
          transform: translateY(-50%) rotate(180deg);
          svg {
            &:nth-child(1) {
              opacity: 1;
            }

            &:nth-child(2) {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

.faq .panel-title a:hover,
.panel-title a:focus,
.panel-title a:active {
  text-decoration: none;
}

.step-image {
  max-height: 500px;
  margin: 10px 25px 20px;
  border: 2px solid #000;
  float: left;
}
.command-block {
  margin: 10px auto;
  clear: both;
}
.command {
  font-style: italic;
  margin-left: 10px;
}
