@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

$tabs-bg-color: #a81524;
$title-color: #ff5a00;

.alarms-wrapper {
  padding-bottom: 50px;

  .caret {
    display: inline-block;
    margin-top: 1rem;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 20px dashed;
    border-top: 4px solid\9;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    position: static;

    &__up {
      transform: rotate(180deg);
    }
  }
  margin: 0 20px;

  .list {
    background-color: #fff;
    background: #fff;

    .item-wrapper {
      border-bottom: 1px solid #c8c8c8;
    }
  }
}
.no-alarms-message {
  font-size: 1.8em;
  margin: 2.6rem;
}

.message-inbox-description {
  display: inline-block;
  width: 100%;
  padding-right: 2rem;
  margin-bottom: 1.5rem;
}
.description-wrapper {
  float: left;
  width: 70%;
}
.description-text {
  font-size: 1.6em;
  line-height: 1.7em;
  color: #414042;
}
.description-icon {
  float: right;
  margin-top: 6em;
  color: #454545;
  & i {
    font-size: 8em;
  }
}
.tabBlock-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
p,
ol,
ul {
  margin-bottom: 1.25rem;
  margin-top: 0;
}
.group::after,
.tabBlock-tabs::after {
  clear: both;
  content: '';
  display: table;
}

.tabBlock {
  margin: 0 0 2.5rem;
}

.tabBlock-tab {
  font-size: 1.6em;
  background-color: #e2e2e2;
  border: none;
  color: #414042;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  float: left;
  flex-grow: 1;
  padding: 1.2rem 1.25rem;
  position: relative;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.tabBlock-tab::before,
.tabBlock-tab::after {
  content: '';
  display: block;
  height: 4px;
  position: absolute;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.tabBlock-tab::before {
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
}
.tabBlock-tab::after {
  background-color: transparent;
  bottom: -2px;
  left: 0;
  right: 0;
}
@media screen and (min-width: 700px) {
  .tabBlock-tab {
    padding-left: 2.8rem;
    padding-right: 2.5rem;
  }
}
.tabBlock-tab.is-active {
  position: relative;
  color: $tabs-bg-color;
  z-index: 1;
}

.tabBlock-content {
  background-color: #fff;
  margin-top: 2rem;
}

.alarms-notification {
  font-size: 1.6em;
  color: #414042;
}
